<div class="file-container full-wid" *ngIf="fileUrl">
  <div class="file">
    <vg-player class="file-height">
      <!--      <vg-overlay-play></vg-overlay-play>-->
      <!--      <vg-buffering></vg-buffering>-->

      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <!--        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>-->
      </vg-scrub-bar>

      <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-time-display
          vgProperty="current"
          vgFormat="mm:ss"
        ></vg-time-display>

        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
        <!--        <vg-volume></vg-volume>-->
      </vg-controls>

      <video
        [vgMedia]="media"
        #media
        id="singleVideo"
        preload="auto"
        [src]="fileUrl"
        type="video/mp4"
      ></video>
    </vg-player>
  </div>
</div>
