<h2 mat-dialog-title>S3 Media Lib - {{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <ejs-splitter #horizontal width="100%" cssClass="splitter-div">
    <e-panes>
      <e-pane size="75%">
        <ng-template #content>
          <app-audio-files
            (onSelect)="selectImage($event)"
            *ngIf="isAudioDialog"
          ></app-audio-files>

          <app-video-files
            (onSelect)="selectImage($event)"
            *ngIf="isVideoDialog"
          ></app-video-files>


          <app-image-files
            (onSelect)="selectImage($event)"
            *ngIf="isImageDialog"
          ></app-image-files>
        </ng-template>
      </e-pane>
      <e-pane size="25%">
        <ng-template #content class="center">
          <app-upload-form
            (onFileUploaded)="newFileUploaded($event)"
            [s3]="s3"
          ></app-upload-form>
        </ng-template>
      </e-pane>
    </e-panes>
  </ejs-splitter>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-button
    cdkFocusInitial
    [disabled]="isSelectButtonDisabled"
    (click)="selectClicked()"
  >
    Select
  </button>
</mat-dialog-actions>
