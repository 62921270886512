<app-sortable-cards-viewer
  (create)="createNewChapter($event)"
  (save)="saveOrder($event)"
  [imageUrlLabel]="imageUrlExtractor"
  [items]="chapters"
  [titleLabel]="titleExtractor"
  [isSelected]="selectedExtractor"
  [status]="statusExtractor"
  (edit)="editChapter($event)"
  (showContent)="selectChapter($event)"
>
  <div title>
    Listing Chapters
  </div>

  <div crumb>
    > {{concept?.media?.text}}
  </div>

  <div secondary>
    <div class="subject-pane">

      <app-form-header
        title="A Chapter"
        subtitleWhenExist="Creating"
        subtitleWhenNotExist="Editing"
        [obj]="selectedChapter"
      ></app-form-header>

      <app-main-form
        [formValue]="selectedChapter"
        [mediaConfig]="mediaConfig"
        [ageGroupsVisible]="true"
        (save)="onSave($event)"
        (regenerateTTS) = "regenerateTTS()"
        [regenerateTTSVisible] = "true"
      ></app-main-form>
    </div>
  </div>
</app-sortable-cards-viewer>
