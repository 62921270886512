export const options: { [key: string]: any } = {
  ken_burns: {
    duration: 5000
  },
  shake: {
    duration: 500
  },
};

const skippedValues = [
  'ken_burns',
  'shake',
];

export class OptionsConverter {

  static mapFormValueToOption(key: string, value: any): any {
    if (skippedValues.includes(key)) {
      return options[key];
    } else {
      return value;
    }
  }

  static mapOptionToFormValue(key: string, value: any): any {
    if (skippedValues.includes(key)) {
      return true;
    }
    return value;
  }

  static formValuesToOptionsObject(obj: any): { [key: string]: boolean } {
    const optionsObject = {};
    for (const [key, value] of Object.entries(obj)) {
      if (!!value) {
        optionsObject[key] = this.mapFormValueToOption(key, value);
      }
    }
    return optionsObject;
  }

  static optionsObjectToFormValues(optionsObj: any): { [key: string]: any } {
    const formValues = {};
    for (const [key, value] of Object.entries(optionsObj)) {
      if (!!value) {
        formValues[key] = this.mapOptionToFormValue(key, value);
      }
    }
    return formValues;
  }
}
