<div>
  <form [formGroup]="form" class="form">
    <div class="actions">
      <div class="form-group row" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <app-button  (onClick)="onSubmit($event)" [disabled]="!form.dirty">Save</app-button>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <app-button cssClass="orange" (onClick)="resetCreatedDate.emit()" >Reset Created Date</app-button>
        <app-button cssClass="orange" (onClick)="regenerateTTS.emit()" >Regenerate TTS</app-button>

      </div>
    </div>

    <div class="info">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Pay Level</label>
        <div class="col-sm-10">
          <app-pay-level-select formControlName="pay_level"></app-pay-level-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Status</label>
        <div class="col-sm-10">
          <app-status-select formControlName="status"></app-status-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Type</label>
        <div class="col-sm-10">
          <ng-select
            [items]="contentByteTypes"
            [clearable]="false"
            bindLabel="title"
            bindValue="value"
            formControlName="type"
            placeholder=""
          ></ng-select>
        </div>
      </div>

      <div class="info">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Play Mode</label>
          <div class="col-sm-10">
            <app-play-mode-select formControlName="play_mode"></app-play-mode-select>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <div class="col-sm-2">
          <label>Language</label>
        </div>
        <div class="col-sm-10">
          <mat-button-toggle-group
            formControlName="language"
            class="toggle-group"
          >
            <mat-button-toggle
              [value]="language"
              *ngFor="let language of languages"
            >
              {{ language }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div> -->

      <div class="row">
        <label class="col-sm-4 col-form-label">Is Title Hidden</label>
        <div class="col-sm-6">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="hide_title"
          />
        </div>
      </div>

        <app-image-input
          label="small icon"
          formControlName="small_icon"
        ></app-image-input>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Title</label>
        <div class="col-sm-10">
          <textarea
            formControlName="title"
            class="form-control"
            dir=""
          ></textarea>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Subtitle</label>
        <div class="col-sm-10">
          <textarea
            formControlName="subtitle"
            class="form-control"
            dir=""
          ></textarea>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Info</label>
        <div class="col-sm-10">
          <textarea
            formControlName="info"
            class="form-control"
            dir=""
          ></textarea>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Admin Info</label>
        <div class="col-sm-10">
          <textarea
            formControlName="admin_info"
            class="form-control"
            dir=""
          ></textarea>
        </div>
      </div>

      <div fxLayout="column" class="form-group" fxLayoutGap="2%">
        <app-media-form
          formControlName="media"
          title="Media"
          [isCollapsed]="false"
          [config]="this.mediaConfig"
        ></app-media-form>
      </div>


      <div fxLayout="column" fxLayoutGap="2%">
        <app-media-form
          formControlName="notification_message"
          title="notification message"
          [isCollapsed]="false"
          [config]="notificationConfig"
        ></app-media-form>
      </div>

    </div>

    <div class="actions">
      <div class="form-group row" fxLayout="row" fxLayoutAlign="end">
        <app-button (onClick)="onSubmit($event)" [disabled]="!form.dirty">Save</app-button>
      </div>
    </div>
  </form>

  <app-dynamic-link
    *ngIf="!!formValue"
    [dynamicLink]="dynamicLink"
    (generateDynamicLink)="onGenerateDynamicLink()"
  ></app-dynamic-link>
</div>
