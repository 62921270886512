import {Directive, EventEmitter, HostListener, Inject, Input, OnDestroy, Optional, Output} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[appFilePicker]',
  exportAs: 'appFilePicker',
})
export class FilePickerDirective implements OnDestroy {

  @Output()
  filesChanged = new EventEmitter<FileList>();

  @Input()
  accept: string = null;

  @Output()
  filesReset = new EventEmitter();
  private form: HTMLFormElement;
  private readonly nativeFileElement: HTMLInputElement;

  constructor(
    @Optional() @Inject(DOCUMENT) private document: Document,
  ) {
    if (this.document) {
      this.form = this.document.createElement('form');
      this.nativeFileElement = this.document.createElement('input');
      this.nativeFileElement.type = 'file';
      this.nativeFileElement.multiple = false;
      if (!!this.accept) {
        this.nativeFileElement.accept = this.accept;
      }
      this.nativeFileElement.addEventListener('change', this.onFilesChanged);
      this.form.appendChild(this.nativeFileElement);
    }
  }

  get files(): FileList | undefined {
    return this.nativeFileElement.files;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  drop(event: DragEvent): void {
    event.preventDefault();
    this.nativeFileElement.files = event.dataTransfer.files;
    this.onFilesChanged();
  }

  @HostListener('onClick', ['$event'])
  onClick(event: Event): void {
    this.nativeFileElement.click();
  }

  ngOnDestroy(): void {
    this.nativeFileElement.removeEventListener('change', this.onFilesChanged);
    this.nativeFileElement.remove();
    this.form.remove();
  }

  reset(): void {
    this.form.reset();
    this.filesReset.emit();
  }

  private onFilesChanged = () => {
    this.filesChanged.emit(this.nativeFileElement.files);
    this.nativeFileElement.value = null;
  };
}
