import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageFile} from '../../models/image-file.model';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-media-lib-files',
  templateUrl: './media-lib-files.component.html',
  styleUrls: ['./media-lib-files.component.scss']
})
export class MediaLibFilesComponent implements OnInit {

  @Output()
  public onSelect = new EventEmitter<ImageFile>();

  @Input()
  title = '';

  @Input()
  query = '';

  @Input()
  isLoading = false;

  @Output()
  public onSearch = new EventEmitter<string>();

  @Output()
  public onRefresh = new EventEmitter();

  constructor() {
  }


  ngOnInit(): void {
  }


  search(): void {
    this.onSearch.emit(this.query);
  }

  refresh(): void {
    this.onRefresh.emit();
  }

}
