<app-splitter>
  <div crumb>
    > Alphazed Admin Boss
  </div>

  <div title>
    Content Editing
  </div>

  <div main>
    <p>
      <app-actions>
        <app-button (onClick)="openImagesLib()">Open Images Library</app-button>
        <app-button (onClick)="openAudiosLib()">Open Audios Library</app-button>
        <app-button (onClick)="openVideosLib()">Open Videos Library</app-button>
      </app-actions>
    </p>

    <p>
      <app-actions>
      <app-button (onClick)="navigateToSubjects()">Show Subjects</app-button>
    </app-actions>
    </p>
    <p>
      <app-actions>
      <app-button cssClass="orange" (onClick)="regenerateDirtyTTS()">Regenerate Dirty TTS</app-button>
    </app-actions>
    </p>
  </div>
</app-splitter>
