<div class="info">
  <div class="info">
    <div class="form-group row" *ngIf="dynamicLinkGenerated else generateDynamicLinkDiv" ngbTooltip="Copy to clipboard"
         [cdkCopyToClipboard]="dynamicLink">
      <label class="col-sm-4">Dynamic Link</label>
      <label class="col-sm-8">{{dynamicLink}}</label>
    </div>
    <ng-template #generateDynamicLinkDiv>
      <div class="form-group row">
        <label class="col-sm-4">Dynamic Link</label>
        <label class="col-sm-8" fxLayoutAlign="end">
          <app-button (onClick)="onGenerateDynamicLink()">Generate</app-button>
        </label>
      </div>
    </ng-template>
  </div>
</div>
