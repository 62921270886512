import {Component, ViewContainerRef} from '@angular/core';
import {NgSelectConfig} from '@ng-select/ng-select';
import {OverlayControllerService} from './core/services/overlay-controller.service';
import {LocalStorageService} from './shared/services/local-storage.service';
import {AuthService} from './modules/authentication/services/auth.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Alphazed Entry Board';
  subscription: Subscription;

  constructor(
    private config: NgSelectConfig,
    private overlayController: OverlayControllerService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public viewContainerRef: ViewContainerRef,
  ) {
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';

    this.subscription = this.localStorageService.changes.subscribe((changes) => {
      if ([AuthService.TOKEN_KEY, null].indexOf(changes.key) >= 0) {
        if (changes.value === null) {
          this.router.navigate(['/auth/login']);
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }

  get displayProgressSpinner(): boolean {
    return this.overlayController.isShown;
  }
}
