import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-stroked-button',
  templateUrl: './stroked-button.component.html',
  styleUrls: ['./stroked-button.component.scss']
})
export class StrokedButtonComponent implements OnInit {

  @Input()
  disabled = false;

  @Output()
  onClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onButtonClick($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.onClick.emit($event);
  }
}
