<div class="panel">
  <div class="header" (click)="collapseOrOpen()" [class.dragging]="!allowOpening" [class.opening]="allowOpening">
    <strong>{{title}}</strong>
    <ng-container *ngIf="allowOpening; else draggingDiv">
      <span class="float-right arrow" [class.arrow-down]="isCollapsed" [class.arrow-up]="!isCollapsed"></span>
    </ng-container>

    <ng-template #draggingDiv>
      <span class="float-right drag_handle"><mat-icon>drag_handle</mat-icon></span>
    </ng-template>
  </div>
  <div [@smoothCollapse]="collapseStatus" [class.body]="!isCollapsed" *ngIf="allowOpening">

    <form [formGroup]="form" class="container">
      <div class="form-group row" *ngIf="config.language">
        <div class="col-sm-2 col-form-label">
          Language
        </div>
        <div class="col-sm-10">
          <ng-select
            formControlName="language"
            placeholder=""
            bindLabel="text"
            bindValue="value"
            [clearable]="false"
          >
            <ng-option *ngFor="let language of languages" [value]="language.value">
              {{language.text}}
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.decimal">
        <div class="col-sm-2 col-form-label">
          Decimal
        </div>
        <div class="col-sm-10">
          <app-calculator-modifier-form [config]="config.decimal" formControlName="decimal"></app-calculator-modifier-form>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.thousand">
        <div class="col-sm-2 col-form-label">
          Thousand
        </div>
        <div class="col-sm-10">
          <app-calculator-modifier-form [config]="config.thousand" formControlName="thousand"></app-calculator-modifier-form>
        </div>
      </div>


      <div class="form-group row" *ngIf="config.rows_count">
        <div class="col-sm-2 col-form-label">
          Rows Count
        </div>
        <div class="col-sm-10">
          <input type="number" class="form-control" formControlName="rows_count">
        </div>
      </div>

      <div class="form-group row" *ngIf="config.columns_count">
        <div class="col-sm-2 col-form-label">
          Columns Count
        </div>
        <div class="col-sm-10">
          <input type="number" class="form-control" formControlName="columns_count">
        </div>
      </div>


      <div class="form-group row" *ngIf="config.error_margin">
        <div class="col-sm-2 col-form-label">
          Error Margin
        </div>
        <div class="col-sm-10">
          <input type="number" class="form-control" formControlName="error_margin">
        </div>
      </div>

      <div class="form-group row" *ngIf="config.word_similarity_threshold">
        <div class="col-sm-2 col-form-label">
          Word Similarity Threshold
        </div>
        <div class="col-sm-10">
          <input type="number" class="form-control" formControlName="word_similarity_threshold">
        </div>
      </div>

      <div class="form-group row" *ngIf="config.sentence_similarity_threshold">
        <div class="col-sm-2 col-form-label">
          Sentence Similarity Threshold
        </div>
        <div class="col-sm-10">
          <input type="number" class="form-control" formControlName="sentence_similarity_threshold">
        </div>
      </div>

      <div class="form-group row" *ngIf="config.hide_original_image">
        <div class="col-sm-2 col-form-label">
          Hide Original Image
        </div>
        <div class="col-sm-10">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="hide_original_image">
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.switch_type">
        <div class="col-sm-2 col-form-label">
          Switch Type
        </div>
        <div class="col-sm-10">
          <div class="form-check">
            <app-switch-type-select formControlName="switch_type"></app-switch-type-select>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.switch_slot">
        <div class="col-sm-2 col-form-label">
          Switch Slot
        </div>
        <div class="col-sm-10">
          <div class="form-check">
            <app-switch-slot-select formControlName="switch_slot"></app-switch-slot-select>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.video_container">
        <div class="col-sm-12">
          <label>video container</label>
          <mat-radio-group formControlName="video_container" color="primary" fxLayout="row" fxLayoutAlign="space-evenly center">
            <mat-radio-button *ngFor="let type of allVideoContainerType" [value]="type.value">{{type.title}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.show_controls">
        <div class="col-sm-12">
           <mat-checkbox class="example-margin" color="primary" formControlName="show_controls">show controls</mat-checkbox>
        </div>
      </div>

    </form>
  </div>
</div>
