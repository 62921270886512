import { AnswerConfig } from '../config/answer.config';

export const selectTextAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const selectMediaAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  properties: {
    font: {
      size_family: true
    },
    ken_burns: false,
    shake: false,
    image_fit:false,
    shadow:false,
    tracing_shape:false
  },
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const pairMatchingTextAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const pairMatchingMediaAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  properties: {
    font: {
      size_family: true
    },
    ken_burns: false,
    shake: false,
    image_fit:false,
    shadow:false,
    tracing_shape:false
  },
};

export const pairMatchingMediaFlipAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  properties: {
    font: {
      size_family: true
    },
    ken_burns: false,
    shake: false,
    image_fit:false,
    shadow:false,
    tracing_shape:false
  },
};

export const sentenceBuildAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const openAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: false,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const fillInBlanksAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const bubblePopAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  properties: {
    font: {
      size_family: true
    },
    shake: false,
    ken_burns: false
  },
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const ctInformationalAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: true,
  image_url: true,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  properties: {
    ken_burns: true,
    shake: true,
    is_ken_burns_enabled: false,
    image_fit: true,
    shadow: true,
    tracing_shape: false
  },
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const calculatorAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: true,
  image_url: false,
  audio_url: false,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: false,
  language: true,
  text_direction: true,
  layout_alignment: true,
};

export const wordTranslationAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: true,
  image_url: false,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const wordBuildAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: true,
  image_url: false,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const spriteDragAndDropAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: false,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  properties: {
    font: null,
    is_ken_burns_enabled: false,
    ken_burns: false,
    shake: false,
    left_coordinate: true,
    top_coordinate: true,
    height: false,
    width: false,
    tracing_shape:false
  },
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};


export const clickToPopAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: true,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  properties: {
    font: null,
    is_ken_burns_enabled: false,
    ken_burns: false,
    shake: false,
    left_coordinate: true,
    top_coordinate: true,
    height: false,
    width: false,
  },
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};


export const speakWithOverlayAnswersConfig: AnswerConfig = {
  required: true,
  text: false,
  text_color: false,
  image_url: true,
  audio_url: false,
  is_correct: false,
  image_color: true,
  background_color: true,
  avatar_image_url: false,
  widget_type: false,
  with_tts: false,
  properties: null,
  language: false,
  text_direction: false,
  layout_alignment: true,
  has_speech_marks: false,
};

export const tracingShapeAnswersConfig: AnswerConfig = {
  required: true,
  text: false,
  text_color: false,
  image_url: false,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: false,
  avatar_image_url: false,
  properties: {
    font: null,
    is_ken_burns_enabled: false,
    ken_burns: false,
    shake: false,
    left_coordinate: false,
    top_coordinate: false,
    height: false,
    width: false,
    tracing_shape:true
  },
  widget_type: false,
  with_tts: true,
  language: false,
  text_direction: false,
  layout_alignment: false,
  has_speech_marks: true,
};

export const switichingAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: true,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: true,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  properties: null,
  language: false,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: false,
};

export const slingshotAnswersConfig: AnswerConfig = {
  required: true,
  text: true,
  text_color: true,
  image_url: true,
  audio_url: true,
  is_correct: true,
  image_color: true,
  background_color: true,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  properties: null,
  language: false,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: false,
};

export const speakOutLoudAnswersConfig: AnswerConfig = {
  required: false,
  text: true,
  text_color: true,
  image_url: true,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};

export const speakOutLoudVideoAnswersConfig: AnswerConfig = {
  required: false,
  text: true,
  text_color: true,
  image_url: true,
  audio_url: true,
  is_correct: false,
  image_color: true,
  background_color: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};
