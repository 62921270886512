import {Component, Inject, OnInit} from '@angular/core';
import {SubjectModel} from '../../models/subject.model';
import {Router} from '@angular/router';
import {ImagesService} from '../../../media-lib/services/images.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {subjectMediaConfig} from '../../utils/media-configs';
import {ListControllerService} from '../../../../shared/services/list-controller.service';
import {InjectNames} from '../../inject-names';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.scss']
})
export class SubjectsComponent implements OnInit {

  public mediaConfig = subjectMediaConfig;
  dynamicLink: string = null;

  constructor(
    public router: Router,
    public media: ImagesService,
    @Inject(InjectNames.SUBJECTS) private listController: ListControllerService<SubjectModel>,
  ) {
    this.listController.fetchAll();
  }

  get subjects(): SubjectModel[] {
    return this.listController.items;
  }

  get selectedSubject(): SubjectModel {
    return this.listController.selected;
  }

  titleExtractor = (item: SubjectModel) => {
    return item?.media?.text;
  };

  imageUrlExtractor = (item: SubjectModel) => {
    return item?.media?.image_url;
  };

  selectedExtractor = (item: SubjectModel) => {
    return this.selectedSubject?.id === item?.id;
  };

  statusExtractor = (item: SubjectModel) => {
    return item?.status ?? 'active';
  }

  ngOnInit(): void {
    this.listController.unselect();
  }

  createNewSubject(): void {
    this.listController.unselect();
  }

  saveOrder(items: any): void {
    this.listController.reorder(null, items);
  }

  selectSubject(obj): void {
    var $event = obj.$event;
    if ($event.metaKey || $event.ctrlKey || $event.keyCode == 91 || $event.keyCode == 224)
    {
      const url = this.router.createUrlTree(['/subjects', obj.item.id])
      window.open(url.toString(), '_blank');
    }else{
    this.router.navigate(['/subjects/', obj.item.id]);
    }
  }

  editSubject(subject: any): void {
    this.dynamicLink = null;
    this.listController.select(subject);
  }

  onSave(value: any): void {
    this.listController.save(value);
  }

  generateDynamicLink(): void {
    this.listController.generateDynamicLink().subscribe(
      value => {
        this.dynamicLink = value.short_link;
      }
    );
  }
}
