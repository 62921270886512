import {Injectable} from '@angular/core';
import {Api} from '../interfaces/api';
import {take} from 'rxjs/operators';
import {DynamicLinkResponseModel} from '../../modules/content-bytes/models/dynamic-link-response.model';
import {Observable} from 'rxjs';

interface X {
  id?: number;
}

@Injectable()
export class ListControllerService<T extends X> {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  // tslint:disable-next-line:variable-name
  _items: T[] = [];

  get items(): T[] {
    return this._items;
  }

  set items(items) {
    this._items = items;
  }

  // tslint:disable-next-line:variable-name
  _selected: T = null;

  get selected(): T {
    return this._selected;
  }

  set selected(selected) {
    this._selected = selected;
  }

  fetchAll(): void {
    this.api.fetchAll().pipe(take(1)).subscribe(
      (data) => {
        this.items = data;
      }
    );
  }

  reorder(item_id, items: any[]): void {
    this.api.reorder(item_id, items)
      .pipe(take(1))
      .subscribe((_) => {
        this.unselect();
      });
  }

  unselect(): void {
    this.selected = null;
  }

  public select(item): void {
    this.selected = item;
  }

  save(value: any): void {
    if (this.selected) {
      this.api.update(this.selected.id, value)
        .pipe(take(1))
        .subscribe((item) => {
          this.updateItem(item);
          this.select(item);
        });
      return;
    }

    this.api.store(value)
      .pipe(take(1))
      .subscribe((item) => {
        this.items.push(item);
        this.select(item);
      });
  }

  generateDynamicLink(): Observable<DynamicLinkResponseModel> {
    const id = this.selected.id;
    return this.api.generateDynamicLink(id)
      .pipe(take(1));
  }

  resetCreatedDate(): any {
    const id = this.selected.id;
    return this.api.resetCreatedDate(id);
  }

  regenerateTTS(): any {
    const id = this.selected.id;
    return this.api.regenerateTTS(id).subscribe();
  }

  copy(): any {
    const id = this.selected.id;
    return this.api.copy(id).subscribe((newItem) => {
       this.insertNewItemAfter(id ,newItem);
       this.reorder(id, this.items);
    })
  }

  private insertNewItemAfter(itemId, newItem){
    const theItem = this.items.find((item) => item.id === itemId);
    const index =   this.items.indexOf(theItem) + 1;
    this.items.splice(index, 0 , newItem);
  }

  private updateItem(newItem): void {
    const theItem = this.items.find((item) => item.id === newItem.id);
    const index = this.items.indexOf(theItem);
    this.items[index] = newItem;
  }
}
