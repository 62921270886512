import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-dynamic-link',
  templateUrl: './dynamic-link.component.html',
  styleUrls: ['./dynamic-link.component.scss']
})
export class DynamicLinkComponent {
  @Output()
  generateDynamicLink = new EventEmitter();

  @Input()
  dynamicLink: string = null;

  get dynamicLinkGenerated(): boolean {
    return !!this.dynamicLink;
  }

  onGenerateDynamicLink(): void {
    this.generateDynamicLink.emit();
  }
}
