<app-sortable-cards-viewer
  (create)="createNewConcept($event)"
  (save)="saveOrder($event)"
  [imageUrlLabel]="imageUrlExtractor"
  [items]="concepts"
  [titleLabel]="titleExtractor"
  [isSelected]="selectedExtractor"
  [status]="statusExtractor"
  (edit)="editConcept($event)"
  (showContent)="selectConcept($event)"
>
  <div title>
    Listing Concepts
  </div>

  <div crumb>
    > {{subject?.media?.text}}
  </div>

  <div secondary>
    <div class="subject-pane">
      <app-form-header
        title="A Concept"
        subtitleWhenExist="Creating"
        subtitleWhenNotExist="Editing"
        [obj]="selectedConcept"
      ></app-form-header>

      <app-main-form
        [formValue]="selectedConcept"
        [mediaConfig]="mediaConfig"
        (save)="onSave($event)"
        (regenerateTTS)="regenerateTTS()"
        [regenerateTTSVisible] = "true"
      ></app-main-form>
    </div>
  </div>
</app-sortable-cards-viewer>
