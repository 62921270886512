<form [formGroup]="form" class="container">

  <div class="form-group row">
    <div class="col-sm-6" *ngIf="config.modifier">
      <div class="form-check">
        <input type="text" class="form-control" formControlName="modifier">
      </div>
    </div>

    <div class="col-sm-3" *ngIf="config.visible">
      <div class="form-check">
        <mat-icon class="visibility-icon" *ngIf="isVisible; else notVisible" (click)="updateVisible(false)">visibility
        </mat-icon>
        <ng-template #notVisible>
          <mat-icon class="visibility-icon" (click)="updateVisible(true)">visibility_off</mat-icon>
        </ng-template>
      </div>
    </div>

    <div class="col-sm-3" *ngIf="config.check_for_this">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" formControlName="check_for_this">
      </div>
    </div>
  </div>

</form>
