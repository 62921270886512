import {MediaPropertiesConfig} from './media-properties.config';

export interface MediaConfig {
  text?: boolean;
  text_color?: boolean;
  image_url?: boolean;
  audio_url?: boolean;
  video_url?: boolean;
  is_correct?: boolean;
  image_color?: boolean;
  background_color?: boolean;
  avatar_image_url?: boolean;
  widget_type?: boolean;
  with_tts?: boolean;
  properties?: MediaPropertiesConfig;
  dynamic_links?: boolean;
  language?: boolean;
  text_direction?: boolean;
  layout_alignment?: boolean;
  has_speech_marks?: boolean;
  subject_type?: boolean;
}


export const defaultMediaConfig: MediaConfig = {
  text: true,
  image_url: true,
  image_color: true,
  audio_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  properties: null,
  dynamic_links: false,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  subject_type: false,
};
