import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CalculatorModifierConfig, defaultCalculatorModifierConfig} from '../../config/calculator-modifier.config';

@Component({
  selector: 'app-calculator-modifier-form',
  templateUrl: './calculator-modifier-form.component.html',
  styleUrls: ['./calculator-modifier-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalculatorModifierFormComponent),
      multi: true,
    }
  ],
})
export class CalculatorModifierFormComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  form: FormGroup;

  @Input()
  config: CalculatorModifierConfig = defaultCalculatorModifierConfig;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  get visible(): FormControl {
    return this.form.controls.visible as FormControl;
  }

  get isVisible(): boolean {
    return this.visible.value;
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouch(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.form.reset({});

    if (value) {
      this.form.patchValue(value);
    }
  }

  ngOnInit(): void {
    this.rebuildForm();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  updateVisible(visible: boolean): void {
    this.visible.setValue(visible);
  }

  private rebuildForm(): void {
    this.form = this.fb.group({
      modifier: '',
      check_for_this: false,
      visible: true,
    });
  }
}
