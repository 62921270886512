<div>

  <div class="form-group row">
    <mat-button-toggle-group [disabled]="disabled" multiple="true" *ngFor="let ageGroup of ageGroups">
      <mat-button-toggle [id]="ageGroup.id" (change)="onButtonValueChanges($event)"
                         [checked]="getButtonStatus(ageGroup.id)">
        {{ageGroup.text}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    <app-button class="button" (onClick)="onSelectAll()">Select All</app-button>
    <app-button class="button" (onClick)="onClearSelection()">Clear Selection</app-button>
  </div>
</div>
