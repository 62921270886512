import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SubjectsComponent} from './screens/subjects/subjects.component';
import {ContentByteComponent} from './screens/content-byte/content-byte.component';
import {AgeGroupsComponent} from './screens/age-groups/age-groups.component';
import {SubjectComponent} from './screens/subject/subject.component';
import {ConceptComponent} from './screens/concept/concept.component';
import {ChapterComponent} from './screens/chapter/chapter.component';


const routes: Routes = [
  {
    path: 'content-bytes/:id',
    component: ContentByteComponent,
  },
  {
    path: 'subjects/:id',
    component: SubjectComponent,
  },
  {
    path: 'subjects',
    component: SubjectsComponent,
  },
  {
    path: 'concepts/:id',
    component: ConceptComponent,
  },
  {
    path: 'chapters/:id',
    component: ChapterComponent,
  },
  {
    path: 'age-groups',
    component: AgeGroupsComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: AgeGroupsComponent,
  },
  {
    path: '**',
    redirectTo: 'age-groups',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentBytesRoutingModule {
}
