<form [formGroup]="form" class="form">
  <div class="form-group row" fxLayoutAlign="center" fxLayout="row">
    <img src="assets/images/logo.png" height="200" width="200">
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">Username</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" formControlName="username">
      <p *ngIf="usernameRequired">
        Username field is required
      </p>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">Password</label>
    <div class="col-sm-10">
      <input type="password" class="form-control" formControlName="password">
      <p *ngIf="passwordRequired">
        Password field is required
      </p>
    </div>
  </div>

  <div class="form-group row" fxLayoutAlign="center">
    <div class="col-sm-12 col-md-7">
      <app-stroked-button (onClick)="login()">
        Login
      </app-stroked-button>
    </div>
  </div>
</form>
