import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ContentBytesRoutingModule} from './content-bytes-routing.module';
import {ContentBitsModule} from '../content-bits/content-bits.module';
import {SubjectsComponent} from './screens/subjects/subjects.component';
import {ContentByteComponent} from './screens/content-byte/content-byte.component';
import {ResizableModule} from 'angular-resizable-element';
import {SharedModule} from '../../shared/shared.module';
import {AgeGroupsComponent} from './screens/age-groups/age-groups.component';
import {SubjectComponent} from './screens/subject/subject.component';
import {ConceptComponent} from './screens/concept/concept.component';
import {ChapterComponent} from './screens/chapter/chapter.component';
import {MainFormComponent} from './components/main-form/main-form.component';
import {ContentByteFormComponent} from './components/content-byte-form/content-byte-form.component';
import {FormHeaderComponent} from './components/form-header/form-header.component';
import {AgeGroupsFormComponent} from './components/age-groups-form/age-groups-form.component';
import {DynamicLinkComponent} from './components/dynamic-link/dynamic-link.component';
import { PlayModeSelectComponent } from './components/play-mode-select/play-mode-select.component';
import { MediaLibModule } from '../media-lib/media-lib.module';


@NgModule({
  declarations: [
    SubjectComponent,
    SubjectsComponent,
    SubjectComponent,
    ContentByteComponent,
    AgeGroupsComponent,
    ConceptComponent,
    ChapterComponent,
    MainFormComponent,
    ContentByteFormComponent,
    FormHeaderComponent,
    AgeGroupsFormComponent,
    DynamicLinkComponent,
    PlayModeSelectComponent,
  ],
  imports: [
    CommonModule,
    ContentBytesRoutingModule,
    SharedModule,
    ResizableModule,
    ContentBitsModule,
    MediaLibModule
  ],
})
export class ContentBytesModule {
}
