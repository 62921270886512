<app-splitter [showForm]="showForm">
  <div title>
    <ng-content select="[title]"></ng-content>
  </div>

  <div crumb>
    <ng-content select="[crumb]"></ng-content>
  </div>

  <div actions fxLayout="row wrap" fxLayoutAlign="end" class="main-actions">
    <app-main-actions
      (create)="onCreate()"
      (save)="onSave(items)"
      (import)="onImport($event)"
      [showImport]="showImport"
    >
    </app-main-actions>
  </div>

  <div main>
    <div [sortablejs]="items" class="cards-list">
      <app-card
        *ngFor="let item of items"
        class="card-in-listing"
        [id]="item.id"
        [image]="extractImageUrl(item)"
        [title]="extractTitle(item)"
        [tags]="extractTags(item)"
        [selected]="extractSelected(item)"
        [isActive]="isActive(item)"
        (edit)="onEdit(item)"
        (showContent)="onShowContent(item, $event)"
        (copyItem)="onCopy(item)"
        [showCopyButton]="showCopyButton"
      ></app-card>
    </div>
  </div>

  <div secondary>
    <ng-content select="[secondary]"></ng-content>
  </div>

</app-splitter>
