<div fxLayout="row" class="snack-container" fxLayoutGap="5%" fxLayoutAlign="space-between">
  <mat-icon>{{icon}}</mat-icon>

  <div class="error-form">
    <h2 class="header" *ngIf="data?.data?.title">{{data.data.title}}</h2>
    <div class="message" *ngIf="data?.data?.message">{{data.data.message}}</div>
    <div class="html" [innerHTML]="data?.data?.html | safeHtml" *ngIf="data.data.html"></div>
  </div>

  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
