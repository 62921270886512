<app-sortable-cards-viewer
  (create)="createNewContentByte($event)"
  (save)="saveOrder($event)"
  [imageUrlLabel]="imageUrlExtractor"
  [tagsLabel]="tagsExtractor"
  [items]="contentBytes"
  [titleLabel]="titleExtractor"
  [isSelected]="selectedExtractor"
  [status]="statusExtractor"
  (edit)="editContentByte($event)"
  [showImport]="!!chapter?.id"
  (import)="importExcelSheet($event)"
  (showContent)="selectContentByte($event)"
  (copyItem)="copyContentByte($event)"
  [showCopyButton]="true"
>
  <div title>
    Listing ContentBytes
  </div>

  <div crumb>
    > {{chapter?.media?.text}}
  </div>

  <div secondary>
    <div class="subject-pane">

      <app-form-header
        title="A Content Byte"
        subtitleWhenExist="Creating"
        subtitleWhenNotExist="Editing"
        [obj]="selectedContentByte"
      ></app-form-header>

      <app-content-byte-form
        [formValue]="selectedContentByte"
        (save)="onSave($event)"
        (generateDynamicLink)="generateDynamicLink()"
        [dynamicLink]="dynamicLink"
        (resetCreatedDate)="resetCreatedDate()"
        (regenerateTTS) = "regenerateTTS()"
      ></app-content-byte-form>
    </div>
  </div>
</app-sortable-cards-viewer>
