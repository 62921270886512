import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-audio-file',
  templateUrl: './audio-file.component.html',
  styleUrls: ['./audio-file.component.scss']
})
export class AudioFileComponent implements OnInit {

  @Input()
  fileUrl: string;

  ngOnInit(): void {
  }

}
