import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {ModulesModule} from './modules/modules.module';
import {CoreModule} from './core/core.module';
import {SplitterModule} from '@syncfusion/ej2-angular-layouts';
import {SortablejsModule} from 'ngx-sortablejs';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {OverlayInterceptor} from './core/interceptors/overlay.interceptor';
import {ActionNames} from './modules/content-bytes/action-names';
import {ApiManagerService} from './shared/services/api-manager.service';
import {ContentByteModel} from './modules/content-bytes/models/content-byte.model';
import {ContentBitModel} from './modules/content-bits/models/content-bit.model';
import {SubjectModel} from './modules/content-bytes/models/subject.model';
import {ConceptModel} from './modules/content-bytes/models/concept.model';
import {ChapterModel} from './modules/content-bytes/models/chapter.model';
import {ItemControllerService} from './shared/services/item-controller.service';
import {BucketFoldersInjectNames, InjectNames} from './modules/content-bytes/inject-names';
import {ListControllerService} from './shared/services/list-controller.service';
import {S3Service} from './modules/media-lib/services/s3.service';
import {BearerHttpClient} from './modules/authentication/services/bearer-http-client.service';
import {AuthService} from './modules/authentication/services/auth.service';
import {AgeGroupModel} from './modules/content-bytes/models/age-group.model';
import { TracingShapeModel } from './modules/content-bits/models/tracing-shape.model';
import { Action } from 'rxjs/internal/scheduler/Action';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    ModulesModule,
    CoreModule,
    AppRoutingModule,
    SplitterModule,
    SortablejsModule.forRoot({animation: 150}),
    FlexLayoutModule,
    NgbModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OverlayInterceptor,
      multi: true,
    },
    {
      provide: ActionNames.SUBJECTS,
      useClass: ApiManagerService,
      useFactory: (authService: AuthService, httpClient: BearerHttpClient) => {
        return new ApiManagerService<SubjectModel>(
          httpClient,
          authService,
          ActionNames.SUBJECTS,
          ActionNames.SHOW_SUBJECT_DYNAMIC_LINK,
          null
        );
      },
      deps: [
        AuthService,
        BearerHttpClient,
      ]
    },
    {
      provide: ActionNames.CONCEPTS,
      useClass: ApiManagerService,
      useFactory: (authService: AuthService, httpClient: BearerHttpClient) => {
        return new ApiManagerService<ConceptModel>(httpClient, authService, ActionNames.CONCEPTS, null , null);
      },
      deps: [
        AuthService,
        BearerHttpClient,
      ]
    },
    {
      provide: ActionNames.CHAPTERS,
      useClass: ApiManagerService,
      useFactory: (authService: AuthService, httpClient: BearerHttpClient) => {
        return new ApiManagerService<ChapterModel>(httpClient, authService, ActionNames.CHAPTERS, null , null);
      },
      deps: [
        AuthService,
        BearerHttpClient,
      ]
    },
    {
      provide: ActionNames.CONTENT_BYTES,
      useClass: ApiManagerService,
      useFactory: (authService: AuthService, httpClient: BearerHttpClient) => {
        return new ApiManagerService<ContentByteModel>(
          httpClient,
          authService,
          ActionNames.CONTENT_BYTES,
          ActionNames.PLAY_CONTENT_BYTE_DYNAMIC_LINK,
          ActionNames.RESET_CREATED_DATE_OF_CONTENT_BYTE
        );
      },
      deps: [
        AuthService,
        BearerHttpClient,
      ]
    },
    {
      provide: ActionNames.CONTENT_BITS,
      useFactory: (authService: AuthService, httpClient: BearerHttpClient) => {
        return new ApiManagerService<ContentBitModel>(httpClient, authService, ActionNames.CONTENT_BITS, null , null);
      },
      deps: [
        AuthService,
        BearerHttpClient,
      ]
    },
    {
      provide: ActionNames.AGE_GROUPS,
      useFactory: (authService: AuthService, httpClient: BearerHttpClient) => {
        return new ApiManagerService<AgeGroupModel>(httpClient, authService, ActionNames.AGE_GROUPS, null , null);
      },
      deps: [
        AuthService,
        BearerHttpClient
      ]
    },
    {
      provide: ActionNames.TRACING_SHAPES,
      useFactory: (authService: AuthService, httpClient: BearerHttpClient) => {
        return new ApiManagerService<TracingShapeModel>(httpClient, authService, ActionNames.TRACING_SHAPES, null , null);
      },
      deps: [
        AuthService,
        BearerHttpClient
      ]
    },
    {
      provide: InjectNames.SUBJECTS,
      useFactory: (api) => {
        return new ListControllerService(api);
      },
      deps: [
        ActionNames.SUBJECTS,
      ]
    },
    {
      provide: InjectNames.SUBJECT_ITEM,
      useFactory: (pApi, cApi) => {
        return new ItemControllerService(pApi, cApi, 'concepts');
      },
      deps: [
        ActionNames.SUBJECTS,
        ActionNames.CONCEPTS,
      ]
    },
    {
      provide: InjectNames.CONCEPT_ITEM,
      useFactory: (pApi, cApi) => {
        return new ItemControllerService(pApi, cApi, 'chapters');
      },
      deps: [
        ActionNames.CONCEPTS,
        ActionNames.CHAPTERS,
      ]
    },
    {
      provide: InjectNames.CHAPTER_ITEM,
      useFactory: (pApi, cApi) => {
        return new ItemControllerService(pApi, cApi, 'content_bytes');
      },
      deps: [
        ActionNames.CHAPTERS,
        ActionNames.CONTENT_BYTES,
      ]
    },
    {
      provide: InjectNames.CONTENT_BYTE_ITEM,
      useFactory: (pApi, cApi) => {
        return new ItemControllerService(pApi, cApi, 'content_bits');
      },
      deps: [
        ActionNames.CONTENT_BYTES,
        ActionNames.CONTENT_BITS,
      ]
    },
    {
      provide: BucketFoldersInjectNames.AUDIOS,
      useFactory: (authService: AuthService) => {
        const s3 = new S3Service(authService, 'audios_folder');
        s3.fetchAllFiles('');
        return s3;
      },
      deps: [
        AuthService,
      ]
    },
    {
      provide: BucketFoldersInjectNames.IMAGES,
      useFactory: (authService: AuthService) => {
        const s3 = new S3Service(authService, 'images_folder');
        s3.fetchAllFiles('');
        return s3;
      },
      deps: [
        AuthService,
      ]
    },
    {
      provide: BucketFoldersInjectNames.EXCEL,
      useFactory: (authService: AuthService) => {
        return new S3Service(authService, 'excel_folder');
      },
      deps: [
        AuthService,
      ]
    },
    {
      provide: BucketFoldersInjectNames.VIDEOS,
      useFactory: (authService: AuthService) => {
        const s3 = new S3Service(authService, 'videos_folder');
        s3.fetchAllFiles('');
        return s3;
      },
      deps: [
        AuthService,
      ]
    },
    {
      provide: InjectNames.AGE_GROUPS,
      useFactory: (api) => {
        return new ListControllerService(api);
      },
      deps: [
        ActionNames.AGE_GROUPS
      ]
    },
    {
      provide: InjectNames.TRACING_SHAPES,
      useFactory: (api) => {
        return new ListControllerService(api);
      },
      deps: [
        ActionNames.TRACING_SHAPES
      ]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
