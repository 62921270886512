import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LoginModel} from '../../models/login.model';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  form: FormGroup;

  @Output()
  onLogin = new EventEmitter<LoginModel>();

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      username: [''],
      password: [''],
    });
  }

  get usernameControl(): FormControl {
    return this.form.controls.username as FormControl;
  }

  get passwordControl(): FormControl {
    return this.form.controls.password as FormControl;
  }

  get usernameRequired(): boolean {
    return this.usernameControl.hasError('required');
  }

  get passwordRequired(): boolean {
    return this.passwordControl.hasError('required');
  }

  ngOnInit(): void {
  }

  login(): void {
    const {value, valid} = this.form;

    if (!valid) {
      return;
    }

    this.onLogin.emit(value);
  }
}
