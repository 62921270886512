import {Injectable} from '@angular/core';
import {AuthService} from '../../authentication/services/auth.service';
import {TashkeelModel} from '../models/tashkeel.model';
import {TashkeelResponseModel} from '../models/tashkeel-response.model';
import {Observable} from 'rxjs';
import {BearerHttpClient} from '../../authentication/services/bearer-http-client.service';
import {ActionNames} from '../../content-bytes/action-names';

@Injectable({
  providedIn: 'root'
})
export class TashkeelService {
  private readonly url: string;

  constructor(
    private auth: AuthService,
    private http: BearerHttpClient,
  ) {
    this.url = auth.urlByAction(ActionNames.GET_TASHKEEL);
  }

  get tashkeelPattern(): string {
    const tashkeelChars = [
      'َ',
      'ً',
      'ُ',
      'ٌ',
      'ِ',
      'ٍ',
      '',
      'ْ',
      'ّ',
    ];
    return '[' + tashkeelChars.join('|') + ']';
  }

  tashkeel(model: TashkeelModel): Observable<TashkeelResponseModel> {
    return this.http.post<TashkeelResponseModel>(this.url, model);
  }

  strip(text: string): string {
    return text.replace(new RegExp(this.tashkeelPattern, 'g'), '');
  }
}
