import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { S3Service } from '../../services/s3.service';
import { ImageFile } from '../../models/image-file.model';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
})
export class UploadFormComponent implements OnInit {
  form: FormGroup;
  files: [];
  uploadPromises: [];

  public isLoading = false;
  public message?: string = null;
  public error?: string = null;

  @Input()
  public s3: S3Service;

  @Output()
  public onFileUploaded = new EventEmitter<ImageFile>();

  constructor(private fb: FormBuilder, public cd: ChangeDetectorRef) {
    this.form = this.fb.group({
      file: ['', Validators.required],
    });
  }

  public getExtension(fileName): string {
    if (fileName) {
      return fileName.split('.').pop();
    }

    return '';
  }

  public getFileName(fileName): string {
    if (fileName) {
      return fileName.split('.')[0];
    }

    return '';
  }

  ngOnInit(): void {}

  async submit(): Promise<void> {
    this.isLoading = true;
    this.message = '';
    this.error = '';
    this.uploadFiles();
  }

  onFileInput(event: any): void {
    if (event.target.files && event.target.files.length) {
      this.files = event.target.files;

      this.form.patchValue({
        file: 'uploaded',
      });

      this.cd.markForCheck();
    } else {
      this.form.patchValue({
        file: null,
      });
    }
  }

  private async updateIfNotExists(file): Promise<void> {
    try {
      await this.checkFileExistence(file);
    } catch (_) {
      await this.uploadFile(file);
    }
  }

  private async uploadFiles() {
    try {
      var filesList = Array.from(this.files);
      for (var i in filesList) {
        await this.updateIfNotExists(filesList[i]);
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }

  private async uploadFile(file): Promise<void> {
    try {
      const uploadedFile = await this.s3.uploadFile(
        file,
        this.getFileName(file.name),
        this.getExtension(file.name)
      );
      this.onFileUploaded.emit(uploadedFile);
      this.message += file.name + ' Uploaded successfully <br/>';
    } catch (e) {
      this.error += file.name + 'Failed, try again <br/>';
    }
  }

  private async checkFileExistence(file): Promise<void> {
    await this.s3.exists(
      this.getFileName(file.name),
      this.getExtension(file.name)
    );
    this.error += 'there is already a file with "' + file.name + '" name <br/>';
  }
}
