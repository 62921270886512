import {AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {defaultMediaConfig, MediaConfig} from '../../config/media.config';
import {SortablejsOptions} from 'ngx-sortablejs/lib/sortablejs-options';
import { AnswerConfig, defaultAnswerConfig } from '../../config/answer.config';

@Component({
  selector: 'app-answers-form',
  templateUrl: './answers-form.component.html',
  styleUrls: ['./answers-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnswersFormComponent),
      multi: true,
    }
  ]
})
export class AnswersFormComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {
  @Input()
  config: AnswerConfig = defaultAnswerConfig;


  form: FormGroup;
  isDragging = false;
  private subscription: Subscription;

  constructor(private fb: FormBuilder) {
    this.form = fb.group({
      answers: fb.array([]),
    });
  }

  get answersForm(): FormArray {
    return this.form.controls.answers as FormArray;
  }

  get answers(): FormArray {
    return this.form.controls.answers as FormArray;
  }

  get sortablejsOptions(): SortablejsOptions {
    return {
      disabled: !this.isDragging,
    };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  setAnswers(answers: any[]): void {
    answers.forEach((item) => {
      this.answersForm.push(this.fb.control(item));
    });
  }

  ngOnInit(): void {
  }

  addNewAnswer(): void {
    this.answersForm.push(this.createNewAnswer());
    this.answersForm.markAsDirty();
  }

  removeAnswer(i: number): void {
    this.answersForm.removeAt(i);
    this.answersForm.markAsDirty();
  }

  ngAfterViewInit(): void {
    this.subscription = this.form.valueChanges.subscribe((value) => {
      const answers = value.answers;
      this.onChange(answers);
      this.onTouch(answers);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.answersForm.clear();

    if (obj) {
      this.patchAnswers(obj);
    }
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  disableDragging(): void {
    this.isDragging = false;
  }

  enableDragging(): void {
    this.isDragging = true;
  }

  private patchAnswers(answers): void {
    if (answers?.length > 0) {
      this.setAnswers(answers);
      return;
    }

    if(this.config.required ?? true)
      this.addNewAnswer();
  }

  private createNewAnswer(): FormControl {
    return this.fb.control(null);
  }
}
