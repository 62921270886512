import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotAuthenticatedGuard} from './authentication/guards/not-authenticated.guard';
import {AuthenticatedGuard} from './authentication/guards/authenticated.guard';


const routes: Routes = [
  {
    path: 'auth',
    canActivateChild: [NotAuthenticatedGuard],
    pathMatch: 'prefix',
    loadChildren: () => import('./authentication/authentication.module').then((r) => r.AuthenticationModule)
  },
  {
    path: '',
    pathMatch: 'prefix',
    canActivate: [AuthenticatedGuard],
    canActivateChild: [AuthenticatedGuard],
    loadChildren: () => import('./content-bytes/content-bytes.module').then((r) => r.ContentBytesModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModulesRoutingModule {
}
