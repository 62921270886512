import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { inputs } from '@syncfusion/ej2-angular-layouts/src/splitter/splitter.component';
import {Options} from "sortablejs";

@Component({
  selector: 'app-sortable-tiles-viewer',
  templateUrl: './sortable-tiles-viewer.component.html',
  styleUrls: ['./sortable-tiles-viewer.component.scss']
})
export class SortableTilesViewerComponent implements OnInit {

  @Input()
  items: any[];

  @Input()
  imageUrlLabel: Function;

  @Input()
  titleLabel: Function;

  @Input()
  tagsLabel: Function;

  @Input()
  status: Function;

  @Input()
  textDirection:(_: any) => string;

  @Input()
  isSelected: Function;

  @Output()
  save = new EventEmitter<any>();

  @Output()
  copyItem = new EventEmitter<any>();

  @Output()
  create = new EventEmitter();

  @Output()
  showContent = new EventEmitter<any>();

  @Output()
  edit = new EventEmitter<any>();

  showForm: boolean = false;

  sortableOptions: Options = {
    direction: 'vertical',
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  extractImageUrl(item: any) {
    if (this.imageUrlLabel) {
      return this.imageUrlLabel(item);
    }

    return null;
  }

  extractTitle(item: any) {
    if (this.titleLabel) {
      return this.titleLabel(item);
    }

    return null;
  }

  extractStatus(item : any){
    if(this.status){
      return this.status(item);
    }
    return null;
  }

  isActive(item: any){
    return this.extractStatus(item) === "active"
  }

  onCreate() {
    this.create.emit();
    this.showForm = true;
  }

  onSave() {
    this.save.emit(this.items);
  }

  extractTags(item: any) {
    if (this.tagsLabel) {
      return this.tagsLabel(item);
    }

    return null;
  }

  extractSelected(item: any) {
    if (this.isSelected) {
      return this.isSelected(item);
    }

    return false;
  }

  extractTextDirection(item: any) {
    if (this.textDirection) {
      return this.textDirection(item);
    }

    return "RTL";
  }

  onEdit(item: any) {
    this.edit.emit(item);
    this.showForm = true;
  }

  onCopy(item: any) {
    this.copyItem.emit(item);
  }


  onShowContent(item: any) {
    this.showContent.emit(item);
  }
}
