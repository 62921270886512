import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoginModel} from '../../models/login.model';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private loginSubscription: Subscription;

  constructor(
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
  }

  login(credentials: LoginModel): void {
    this.loginSubscription = this.authService.login(credentials).subscribe((value) => {
    });
  }
}
