import {AfterViewInit, Component, forwardRef, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

export enum Status {
  active = 'active',
  unavailable = 'unavailable',
  inactive = 'inactive',
}

export const defaultSelectedStatus = Status.active;

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusSelectComponent),
      multi: true,
    }
  ],
})
export class StatusSelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  form: FormGroup;
  allStatus = [
    {title: 'Active', value: Status.active, },
    {title: 'Unavailable', value: Status.unavailable, },
    {title: 'Inactive', value: Status.inactive, },
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      status: [this.defaultStatus],
    });
  }

  get defaultStatus(): string {
    return defaultSelectedStatus;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.form.patchValue({
        status: changes.status
      });
    }

    this.form.markAsPristine();
  }

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((x) => {
      this.onChange(x.status);
      this.onTouch(x.status);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue({status: val});
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
