import { AfterViewInit, Component, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum switchSlot {
  vertical1 = "vertical-1",
  vertical2 = "vertical-2",
  vertical3 = "vertical-3",
  horizontal1 = "horizontal-1",
  horizontal2 = "horizontal-2",
  horizontal3 = "horizontal-3",
}

export const defaultSwitchSlot = switchSlot.horizontal1;

@Component({
  selector: 'app-switch-slot-select',
  templateUrl: './switch-slot-select.component.html',
  styleUrls: ['./switch-slot-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchSlotSelectComponent),
      multi: true,
    }
  ],
})

export class SwitchSlotSelectComponent implements OnInit , AfterViewInit, OnChanges, ControlValueAccessor {

  switchSlot: FormControl;
  allSwitchSlots: { title: string, value: string }[] = [
    {
      title: switchSlot.vertical1.valueOf(),
      value: switchSlot.vertical1.valueOf(),
    },
    {
      title: switchSlot.vertical2.valueOf(),
      value: switchSlot.vertical2.valueOf(),
    },
    {
      title: switchSlot.vertical3.valueOf(),
      value: switchSlot.vertical3.valueOf(),
    },
    {
      title: switchSlot.horizontal1.valueOf(),
      value: switchSlot.horizontal1.valueOf(),
    },
    {
      title: switchSlot.horizontal2.valueOf(),
      value: switchSlot.horizontal2.valueOf(),
    },
    {
      title: switchSlot.horizontal3.valueOf(),
      value: switchSlot.horizontal3.valueOf(),
    }
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.switchSlot = this.fb.control(this.defaultSwitchSlot);
  }

  get defaultSwitchSlot(): string {
    return defaultSwitchSlot;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.switchSlot) {
      this.switchSlot.patchValue(changes.switchSlot);
    }

    this.switchSlot.markAsPristine();
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  ngAfterViewInit(): void {
    this.switchSlot.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: any): void {
    if (val) {
      this.switchSlot.patchValue(val);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.switchSlot.disable();
    } else {
      this.switchSlot.enable();
    }
  }
}
