import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ImagesService} from '../../services/images.service';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageInputComponent),
      multi: true,
    }
  ]
})
export class ImageInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  url: FormControl;
  @Input()
  label: string;

  constructor(
    private fb: FormBuilder,
    private mediaLib: ImagesService
  ) {
    this.url = this.fb.control(null);
  }

  ngAfterViewInit(): void {
    this.url.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    });
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.url.patchValue(obj);
    } else {
      this.url.reset();
    }
  }

  ngOnInit(): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.url.disable();
    } else {
      this.url.enable();
    }
  }


  openMediaLib(): void {
    this.mediaLib.open().subscribe((x) => {
      if (!x) {
        return;
      }

      this.url.patchValue(x.selected_image.name);
    });
  }

  clearValue(): void {
    this.url.patchValue(null);
  }
}
