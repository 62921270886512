import {Component, Inject, Input, OnInit} from '@angular/core';
import {S3Service} from '../../../modules/media-lib/services/s3.service';
import {BucketFoldersInjectNames} from '../../../modules/content-bytes/inject-names';

@Component({
  selector: 'app-s3-audio-file',
  templateUrl: './s3-audio-file.component.html',
  styleUrls: ['./s3-audio-file.component.scss']
})
export class S3AudioFileComponent implements OnInit {

  @Input()
  fileName: string;

  constructor(
    @Inject(BucketFoldersInjectNames.AUDIOS) public s3service: S3Service
  ) {
  }

  get fileUrl(): string {
    return this.s3service.objectUrl(this.fileName);
  }

  ngOnInit(): void {
  }

}
