<div class="center p-search form-group row">
  <label class="col-sm-4 col-form-label">Search in Files</label>
  <div class="input-group col-sm-7">
    <input type="text" class="form-control" [(ngModel)]="query" (keydown.enter)="search()">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="query=''" type="button">
        <mat-icon>close</mat-icon>
      </button>
      <button class="btn btn-outline-secondary" (click)="search()" type="button">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
  <div class="input-group col-sm-1">
    <app-button (onClick)="refresh()">Refresh
      <mat-icon>refresh</mat-icon>
    </app-button>
  </div>
</div>

<div *ngIf="isLoading" class="center">
  <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
</div>

<ng-content select="[files]"></ng-content>
