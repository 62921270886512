import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewerComponent} from './components/viewer/viewer.component';
import {NavComponent} from "./components/nav/nav.component";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../shared/shared.module";
import {InitCallGuard} from "./guards/init-call.guard";


@NgModule({
  declarations: [
    ViewerComponent,
    NavComponent,
  ],
  exports: [
    NavComponent,
    ViewerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
})
export class CoreModule {
}
