import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  obj: any;

  @Input()
  subtitleWhenExist: string;

  @Input()
  subtitleWhenNotExist: string;

  constructor() {
  }

  get subtitle() {
    return !!this.obj ? this.subtitleWhenNotExist : this.subtitleWhenExist;
  }

  ngOnInit(): void {
  }

}
