<app-splitter [showForm]="showForm">
  <div title>
    <ng-content select="[title]"></ng-content>
  </div>

  <div crumb>
    <ng-content select="[crumb]"></ng-content>
  </div>

  <div actions  fxLayout="row wrap" fxLayoutAlign="end" class="main-actions">
    <app-main-actions
      (create)="onCreate()"
      (save)="onSave()"
    ></app-main-actions>
  </div>

  <div main>
    <div [sortablejs]="items" class="tiles-list" [sortablejsOptions]="sortableOptions">
        <app-tile
          *ngFor="let item of items"
          class="tile-in-listing"
          [id]="item.id"
          [imageUrl]="extractImageUrl(item)"
          [title]="extractTitle(item)"
          [textDirection]="extractTextDirection(item)"
          [tags]="extractTags(item)"
          [selected]="extractSelected(item)"
          [isActive]="isActive(item)"
          (edit)="onEdit(item)"
          (showContent)="onShowContent(item)"
          (copyItem)="onCopy(item)"
        ></app-tile>
    </div>
  </div>

  <div secondary>
    <ng-content select="[secondary]"></ng-content>
  </div>
</app-splitter>
