import {AfterViewInit, Component, forwardRef, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

export enum TtsVoiceName {
  normalWoman = 'Normal Woman',
  normalMan1 = 'Normal Man 1',
  normalMan2 = 'Normal Man 2',
  normalSlowWoman = 'Normal Slow Woman',
  normalSlowMan1 = 'Normal Slow Man 1',
  normalSlowMan2 = 'Normal Slow Man 2',
  seriousWoman = 'Serious Woman',
  seriousMan1 = 'Serious Man 1',
  seriousMan2 = 'Serious Man 2',
  seriousMan2Slow = 'Serious Man 2 Slow',
  boyChild = 'Boy Child',
  girlChild = 'Girl Child',
  softMan1 = 'Soft Man 1',
  softMan2 = 'Soft Man 2'
}

export const defaultTtsVoiceName = TtsVoiceName.normalMan1;

@Component({
  selector: 'app-tts-voice-name-select',
  templateUrl: './tts-voice-name-select.component.html',
  styleUrls: ['./tts-voice-name-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtsVoiceNameSelectComponent),
      multi: true,
    }
  ],
})
export class TtsVoiceNameSelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  voiceName: FormControl;
  allVoiceNames: { title: string, value: string }[] = [
    {
      title: TtsVoiceName.normalWoman.valueOf(),
      value: TtsVoiceName.normalWoman.valueOf(),
    },
    {
      title: TtsVoiceName.normalMan1.valueOf(),
      value: TtsVoiceName.normalMan1.valueOf(),
    },
    {
      title: TtsVoiceName.normalMan2.valueOf(),
      value: TtsVoiceName.normalMan2.valueOf(),
    },
    {
      title: TtsVoiceName.normalSlowWoman.valueOf(),
      value: TtsVoiceName.normalSlowWoman.valueOf(),
    },
    {
      title: TtsVoiceName.normalSlowMan1.valueOf(),
      value: TtsVoiceName.normalSlowMan1.valueOf(),
    },
    {
      title: TtsVoiceName.normalSlowMan2.valueOf(),
      value: TtsVoiceName.normalSlowMan2.valueOf(),
    },
    {
      title: TtsVoiceName.seriousWoman.valueOf(),
      value: TtsVoiceName.seriousWoman.valueOf(),
    },
    {
      title: TtsVoiceName.seriousMan1.valueOf(),
      value: TtsVoiceName.seriousMan1.valueOf(),
    },
    {
      title: TtsVoiceName.seriousMan2.valueOf(),
      value: TtsVoiceName.seriousMan2.valueOf(),
    },
    {
      title: TtsVoiceName.seriousMan2Slow.valueOf(),
      value: TtsVoiceName.seriousMan2Slow.valueOf(),
    },
    {
      title: TtsVoiceName.boyChild.valueOf(),
      value: TtsVoiceName.boyChild.valueOf(),
    },
    {
      title: TtsVoiceName.girlChild.valueOf(),
      value: TtsVoiceName.girlChild.valueOf(),
    },
    {
      title: TtsVoiceName.softMan1.valueOf(),
      value: TtsVoiceName.softMan1.valueOf(),
    },
    {
      title: TtsVoiceName.softMan2.valueOf(),
      value: TtsVoiceName.softMan2.valueOf(),
    },
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.voiceName = this.fb.control(this.defaultVoiceName);
  }

  get defaultVoiceName(): string {
    return defaultTtsVoiceName;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.voice_name) {
      this.voiceName.patchValue(changes.voice_name);
    }

    this.voiceName.markAsPristine();
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  ngAfterViewInit(): void {
    this.voiceName.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: any): void {
    if (val) {
      this.voiceName.patchValue(val);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.voiceName.disable();
    } else {
      this.voiceName.enable();
    }
  }
}
