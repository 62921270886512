import {Injectable, OnDestroy} from '@angular/core';
import 'rxjs/add/operator/share';
import {Observable, Subject} from 'rxjs';
import {share} from 'rxjs/operators';

export interface StorageKeyValue {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements OnDestroy {
  public onSubject: Subject<StorageKeyValue> = new Subject<StorageKeyValue>();

  constructor() {
    this.start();
  }

  public get changes(): Observable<StorageKeyValue> {
    return this.onSubject.asObservable().pipe(share());
  }

  ngOnDestroy(): void {
    this.stop();
  }

  public getStorage(): any[] {
    const s = [];
    for (let i = 0; i < localStorage.length; i++) {
      s.push({
        key: localStorage.key(i),
        value: JSON.parse(localStorage.getItem(localStorage.key(i)))
      });
    }
    return s;
  }

  public store(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({key, value: data});
  }

  public clear(key: string): void {
    localStorage.removeItem(key);
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({key, value: null});
  }

  getItem(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next({key: event.key, value: v});
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
