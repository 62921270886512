import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {defaultMediaConfig, MediaConfig} from '../../../content-bits/config/media.config';
import {defaultSelectedStatus, Status} from '../../../../shared/components/status-select/status-select.component';
import { payLevel , defaultSelectedPayLevel } from 'src/app/shared/components/pay-level-select/pay-level-select.component';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';
import { SubjectLevel} from 'src/app/modules/content-bytes/utils/enums'
@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent implements OnInit, OnChanges {

  @Input()
  formValue: any;

  @Output()
  save = new EventEmitter();

  @Output()
  generateDynamicLink = new EventEmitter();

  @Output()
  regenerateTTS = new EventEmitter();

  @Input()
  mediaConfig: MediaConfig = defaultMediaConfig;

  @Input()
  ageGroupsVisible = false;

  @Input()
  subjectLevelVisible = false;

  @Input()
  regenerateTTSVisible = false;

  form: FormGroup;
  @Input()
  dynamicLink: string = null;

  constructor(private fb: FormBuilder, private authService: AuthService) {
      this.createForm();
  }

  allSubjectLevels = [
    {title: 'master', value: SubjectLevel.master, },
    {title: 'subset', value: SubjectLevel.subset, }
  ];


  get languages(): string[] {
    return this.authService.languages;
  }

  get dynamicLinkGenerated(): boolean {
    return !!this.dynamicLink;
  }

  get defaultStatus(): Status {
    return defaultSelectedStatus;
  }

  get defaultLanguage(): String {
    return 'AR';
  }

  get defaultPayLevel(): payLevel {
    return defaultSelectedPayLevel;
  }

  ngOnInit(): void {
    this.updateForm();
  }

  onSubmit(): void {
    const {value} = this.form;

    this.save.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.reset({
      status: this.defaultStatus,
       pay_level:this.defaultPayLevel,
        // language:this.defaultLanguage,
        hide_title:false,
        level:SubjectLevel.subset,
      });

    if (changes.formValue) {
      this.updateForm();
    }

    this.form.markAsPristine();
  }

  onGenerateDynamicLink(): void {
    this.generateDynamicLink.emit();
  }

  private createForm(): void{
    this.form = this.fb.group({
      media: [],
      status: [],
      pay_level: [],
      age_groups_ids: [],
      title:[],
      hide_title:[false],
      subtitle:[],
      small_icon:[],
      info:[],
      admin_info:[],
      // language:['AR'],
      level:[SubjectLevel.subset]
    });
  }

  private updateForm(): void {
    if (this.formValue) {
      this.form.patchValue(this.formValue);
    }
  }
}
