<div class="form-group row">
  <label class="col-sm-2 col-form-label">Is Dirty</label>
  <div class="col-sm-10">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        [ngModel]="isDirty"
        onclick="return false"
      />
    </div>
  </div>
</div>

<form [formGroup]="form">
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">TTS Text</label>
    <div class="col-sm-10">
      <textarea formControlName="text" class="form-control" dir="rtl"></textarea>
    </div>
  </div>

  <div class="form-group row" *ngIf="false">
    <label class="col-sm-2 col-form-label">Tashkeel</label>
    <div class="col-sm-10 actions">
      <app-actions>
        <app-stroked-button class="button" (onClick)="shakkelFull()" [disabled]="isEmptyText">Full</app-stroked-button>
        <app-stroked-button class="button" (onClick)="shakkelReduced()" [disabled]="isEmptyText">Reduced</app-stroked-button>
        <app-stroked-button class="button" (onClick)="stripTashkeel()" [disabled]="isEmptyText">Strip</app-stroked-button>
      </app-actions>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">Voice Name</label>
    <div class="col-sm-10">
      <app-tts-voice-name-select formControlName="voice_name"></app-tts-voice-name-select>
    </div>
  </div>

  <div class="form-group row" *ngIf="ttsAudioUrl">
    <label class="col-sm-2 col-form-label">Audio</label>
    <div class="col-sm-10">
      <app-audio-file [fileUrl]="ttsAudioUrl"></app-audio-file>
    </div>
  </div>


  <div *ngIf="speechMarks.length != 0" class="form-group row">
    <label class="col-sm-2 col-form-label">Speech Marks</label>
    <div class="col-sm-10">
      <p [style.text-align]="layoutAlignment" [style.direction]="textDirection">
        <span class="speech-mark-text" [style.direction]="textDirection" *ngFor="let sm of speechMarks">{{sm}}</span>
      </p>
    </div>
  </div>

</form>
