import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-main-actions',
  templateUrl: './main-actions.component.html',
  styleUrls: ['./main-actions.component.scss']
})
export class MainActionsComponent implements OnInit {

  @Output()
  save = new EventEmitter();

  @Output()
  create = new EventEmitter();

  @Output()
  import = new EventEmitter<FileList>();

  @Input()
  showImport = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onCreate($event: any): void {
    this.create.emit();
  }

  onSave($event: any): void {
    this.save.emit();
  }

  onImport($event: FileList): void {
    this.import.emit($event);
  }
}
