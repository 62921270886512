import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { OptionsConverter } from '../../utils/options.configs';
import {
  defaultPropertiesConfig,
  MediaPropertiesConfig,
} from '../../config/media-properties.config';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-media-properties-form',
  templateUrl: './media-properties-form.component.html',
  styleUrls: ['./media-properties-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaPropertiesFormComponent),
      multi: true,
    },
  ],
})
export class MediaPropertiesFormComponent
  implements OnInit, AfterViewInit, ControlValueAccessor {
  form: FormGroup;

  @Input()
  config: MediaPropertiesConfig = defaultPropertiesConfig;

  isShadowEnable = false;

  constructor(private fb: FormBuilder) {}

  onChange: any = () => {};

  onTouch: any = () => {};

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((x) => {
      const value = OptionsConverter.formValuesToOptionsObject(x);

      this.onChange(value);
      this.onTouch(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    const value = OptionsConverter.formValuesToOptionsObject(this.form.value);
    fn(value);
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.form.reset({});
    this.isShadowEnable = obj?.shadow != null;
    this.onShadowEnableChange();

    if (obj) {
      const value = OptionsConverter.optionsObjectToFormValues(obj);
      this.form.patchValue(value);
    } else {
      this.form.patchValue({
        ken_burns: this.config.is_ken_burns_enabled,
      });
    }
  }

  ngOnInit(): void {
    this.rebuildForm();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  onShadowEnableChange() {
    if (this.isShadowEnable) {
      this.form.addControl(
        'shadow',
        this.fb.group({
          color: ['#ebebeb'],
        })
      );
    } else {
      this.form.removeControl('shadow');
    }
  }

  private rebuildForm(): void {
    this.form = this.fb.group({
      font: [],
      shake: [],
      ken_burns: [this.config.is_ken_burns_enabled],
      image_fit: [],
      top_coordinate: [],
      left_coordinate: [],
      width: [],
      height: [],
      tracing_shape:[]
    });
  }
}
