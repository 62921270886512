import { AfterViewInit, Component, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';


export enum imageFit {
  contain = 'contain',
  cover = 'cover',
  fill = 'fill',
  fitHeight = 'fit-height',
  fitWidth = 'fit-width',
  scaleDown = 'scale-down',
  none = 'none',
}

export const defaultImageFit = imageFit.contain;

@Component({
  selector: 'app-image-fit-select',
  templateUrl: './image-fit-select.component.html',
  styleUrls: ['./image-fit-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageFitSelectComponent),
      multi: true,
    }
  ],
})
export class ImageFitSelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  imageFit: FormControl;
  allimageFits: { title: string, value: string }[] = [
    {
      title: imageFit.contain.valueOf(),
      value: imageFit.contain.valueOf(),
    },
    {
      title: imageFit.cover.valueOf(),
      value: imageFit.cover.valueOf(),
    },
    {
      title: imageFit.fill.valueOf(),
      value: imageFit.fill.valueOf(),
    },
    {
      title: imageFit.fitHeight.valueOf(),
      value: imageFit.fitHeight.valueOf(),
    },
    {
      title: imageFit.fitWidth.valueOf(),
      value: imageFit.fitWidth.valueOf(),
    },
    {
      title: imageFit.scaleDown.valueOf(),
      value: imageFit.scaleDown.valueOf(),
    },
    {
      title: imageFit.none.valueOf(),
      value: imageFit.none.valueOf(),
    },
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.imageFit = this.fb.control(this.defaultimageFit);
  }

  get defaultimageFit(): string {
    return defaultImageFit;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image_fit) {
      this.imageFit.patchValue(changes.image_fit);
    }

    this.imageFit.markAsPristine();
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  ngAfterViewInit(): void {
    this.imageFit.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: any): void {
    if (val) {
      this.imageFit.patchValue(val);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.imageFit.disable();
    } else {
      this.imageFit.enable();
    }
  }

}
