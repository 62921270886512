import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {defaultFontOptions, FontOptions} from "../../config/media-properties.config";

@Component({
  selector: 'app-font-options-form',
  templateUrl: './font-options-form.component.html',
  styleUrls: ['./font-options-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FontOptionsFormComponent),
      multi: true,
    }
  ]
})
export class FontOptionsFormComponent implements OnInit, ControlValueAccessor {
  form: FormGroup;

  @Input()
  config: FontOptions = defaultFontOptions;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.form.reset({});

    if (obj) {
      this.form.patchValue(obj);
    }
  }

  ngOnInit(): void {
    this.rebuildForm();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private rebuildForm(): void {
    this.form = this.fb.group({
      size_family: []
    });
  }

}
