export enum InjectNames {
  SUBJECTS = 'SUBJECTS',
  SUBJECT_ITEM = 'SUBJECT_ITEM',
  CONCEPT_ITEM = 'CONCEPT_ITEM',
  CHAPTER_ITEM = 'CHAPTER_ITEM',
  CONTENT_BYTE_ITEM = 'CONTENT_BYTE_ITEM',
  AGE_GROUPS = 'AGE_GROUPS',
  TRACING_SHAPES='TRACING_SHAPES'
}

export enum BucketFoldersInjectNames {
  AUDIOS = 'AUDIOS',
  IMAGES = 'IMAGES',
  EXCEL = 'EXCEL',
  VIDEOS = 'VIDEOS'
}
