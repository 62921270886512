import {AfterViewInit, Component, forwardRef, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

export enum  playMode{
  default = 'default',
  auto_on_correct = 'auto_on_correct',
}

export const defaultSelectedPlayMode = playMode.default;

@Component({
  selector: 'app-play-mode-select',
  templateUrl: './play-mode-select.component.html',
  styleUrls: ['./play-mode-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlayModeSelectComponent),
      multi: true,
    }
  ],
})
export class PlayModeSelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  form: FormGroup;
  allPlayModes = [
    {title: 'default', value: playMode.default, },
    {title: 'auto on correct', value: playMode.auto_on_correct, },
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      play_mode: [this.defaultPlayMode],
    });
  }

  get defaultPlayMode(): string {
    return defaultSelectedPlayMode;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.play_mode) {
      this.form.patchValue({
        play_mode: changes.play_mode
      });
    }

    this.form.markAsPristine();
  }

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((x) => {
      this.onChange(x.play_mode);
      this.onTouch(x.play_mode);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue({play_mode: val});
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
