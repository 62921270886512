import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ImagesService} from '../../../media-lib/services/images.service';
import {AgeGroupModel} from '../../models/age-group.model';
import {AudiosService} from '../../../media-lib/services/audios.service';
import {VideosService} from '../../../media-lib/services/videos.service';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { ActionNames } from '../../action-names';
import { BearerHttpClient } from 'src/app/modules/authentication/services/bearer-http-client.service';


@Component({
  selector: 'app-age-groups',
  templateUrl: './age-groups.component.html',
  styleUrls: ['./age-groups.component.scss']
})
export class AgeGroupsComponent implements OnInit {

  public subjects: AgeGroupModel[] = [
    {
      id: 123,
    },
    {
      id: 123,
    },
  ];

  constructor(
    public router: Router,
    public images: ImagesService,
    public audios: AudiosService,
    public videos: VideosService,
    private http: BearerHttpClient,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
  }


  navigateToSubjects(): void {
    this.router.navigate(['/subjects']);
  }

  openImagesLib(): void {
    this.images.open().subscribe((a) => {
    });
  }

  openAudiosLib(): void {
    this.audios.open().subscribe((a) => {
    });
  }

  openVideosLib(): void {
    this.videos.open().subscribe((a) => {
    });
  }

  regenerateDirtyTTS(): void{
    var url = this.authService.urlByAction(ActionNames.REGENERATE_DIRTY_TTS)
    this.http.post(url, null).subscribe();
  }
}
