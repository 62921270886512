import { Component, Inject, Input, OnInit } from '@angular/core';
import { BucketFoldersInjectNames } from 'src/app/modules/content-bytes/inject-names';
import { S3Service } from 'src/app/modules/media-lib/services/s3.service';

@Component({
  selector: 'app-s3-video-file',
  templateUrl: './s3-video-file.component.html',
  styleUrls: ['./s3-video-file.component.scss']
})
export class S3VideoFileComponent implements OnInit {

  @Input()
  fileName: string;

  constructor(
    @Inject(BucketFoldersInjectNames.VIDEOS) public s3service: S3Service
  ) {
  }

  get fileUrl(): string {
    return this.s3service.objectUrl(this.fileName);
  }

  ngOnInit(): void {
  }
}
