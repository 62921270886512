<div class="upload-form">
  <h4 class="center">Upload Files</h4>

  <form [formGroup]="form">
    <div class="left">
      <button
        class="btn btn-outline-secondary"
        (click)="fileInput.click()"
        type="button"
      >
        Select files
        <input
          #fileInput
          (change)="onFileInput($event)"
          type="file"
          style="display: none"
          accept="audio/mpeg3,.mp3,image/png,image/jpeg,image/jpg,video/mp4,video/*"
          multiple
        />
        <input type="text" formGroupName="file" style="display: none" />
      </button>
      <app-button
        class="save-button"
        *ngIf="!isLoading"
        type="submit"
        [disabled]="!this.files"
        (onClick)="submit()"
        >Save</app-button
      >
    </div>

    <ul *ngIf="this.files">
      Selected files:
      <li *ngFor="let file of this.files">
        {{ file?.name | truncate: [25] }}
      </li>
    </ul>
    <p *ngIf="isLoading" class="center">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="25"
      ></mat-progress-spinner>
    </p>

    <p *ngIf="message" [innerHtml]="message" class="success">
    </p>

    <p *ngIf="error" class="error" [innerHTML]="error"></p>
  </form>
</div>
