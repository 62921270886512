export interface CalculatorModifierConfig {
  modifier: boolean;
  visible: boolean;
  check_for_this: boolean;
}

export const defaultCalculatorModifierConfig: CalculatorModifierConfig = {
  check_for_this: true,
  visible: true,
  modifier: true,
};

