import {Injectable} from '@angular/core';
import {SnackBarService} from '../../shared/services/snack-bar.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../../modules/authentication/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private snackBar: SnackBarService,
    private authService: AuthService,
  ) {
  }

  public handle(e: HttpErrorResponse): void {
    if (e.status === 422) {
      this.handleValidationError(e);
    } else if (e.status === 401) {
      this.handleUnauthorizedError(e);
    } else {
      this.handleUnknownError(e);
    }
  }

  hideSnack(): void {
    this.snackBar.dismiss();
  }

  private showErrorSnack(data, duration): void {
    this.snackBar.openSnackBar(data, 'error', 'Error', duration);
  }

  private handleValidationError(e: HttpErrorResponse): void {
    let errorMessages = '<ul>';

    for (const [key, value] of Object.entries(e.error.message)) {
      errorMessages += `<li>${key}: ${JSON.stringify(value)}</li>`;
    }
    errorMessages += '</ul>';

    this.showErrorSnack({
      title: 'Missing Fields:',
      html: errorMessages,
    }, 0);
  }

  private handleUnknownError(e: HttpErrorResponse): void {
    this.showErrorSnack({title: e.message}, 3000);
  }

  private handleUnauthorizedError(e: HttpErrorResponse): void {
    const errorMessage = `<p>${e.error.message}</p>`;

    this.showErrorSnack({
      title: 'Please re-login!',
      html: errorMessage,
    }, 0);

    this.authService.logout();
  }
}
