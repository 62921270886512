<mat-card [class.selected]="selected">
  <div
  class="header-bar"
  [ngClass]="{
    'inActive': !this.isActive
  }"
></div>
  <mat-card-header>
    <mat-card-subtitle *ngIf="tags">
      <mat-chip-list disabled>
        <mat-chip *ngFor="let tag of tags" disabled>{{tag}}</mat-chip>
      </mat-chip-list>
    </mat-card-subtitle>
    <mat-card-title *ngIf="title" class="title" fxLayoutAlign="start start" dir="rtl">
      {{title | truncate:[40]}}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <app-s3-image-file [fileName]="image" class="s3-file" [height]="fileHeight"></app-s3-image-file>
  </mat-card-content>

  <mat-card-actions fxLayout="row wrap" fxLayoutGap="2px grid" align="end">
    <ng-container *ngIf="selected; else notEditing">
      <app-button class="button">Editing</app-button>
    </ng-container>

    <ng-template #notEditing>
      <app-stroked-button class="button" (onClick)="onEdit($event)">Edit</app-stroked-button>
    </ng-template>

    <app-stroked-button role="link" class="button" (onClick)="onShowContent($event)">Show Content</app-stroked-button>
    <app-stroked-button [hidden]="!showCopyButton" class="button" (onClick)="onCopy($event)" >Copy</app-stroked-button>
  </mat-card-actions>
  <mat-chip-list disabled>
    <mat-chip  disabled>{{id}}</mat-chip>
  </mat-chip-list>
</mat-card>
