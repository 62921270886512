import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './components/login/login-form.component';
import {SharedModule} from '../../shared/shared.module';
import { LoginComponent } from './screens/login/login.component';
import {AuthenticationRoutingModule} from './authentication-routing.module';



@NgModule({
  declarations: [
    LoginFormComponent,
    LoginComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationRoutingModule,
  ]
})
export class AuthenticationModule { }
