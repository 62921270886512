import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {OverlayControllerService} from "../services/overlay-controller.service";
import {catchError, tap} from "rxjs/operators";
import {ErrorHandlerService} from "../services/error-handler.service";

@Injectable({providedIn: 'root'})
export class OverlayInterceptor implements HttpInterceptor {

  constructor(
    private overlayControllerService: OverlayControllerService,
    private errorHandler: ErrorHandlerService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.interceptRequest();

    return next.handle(request).pipe(
      tap((item) => {
        if (item instanceof HttpResponse) {
          this.overlayControllerService.hide();
        }
      }),
      catchError((e) => {
        this.overlayControllerService.hide();

        if (e instanceof HttpErrorResponse) {
          this.errorHandler.handle(e);
        }

        return throwError(e);
      })
    );
  }

  private interceptRequest() {
    this.overlayControllerService.show();
    this.errorHandler.hideSnack();
  }
}
