<app-sortable-cards-viewer
  (create)="createNewSubject()"
  (save)="saveOrder($event)"
  [imageUrlLabel]="imageUrlExtractor"
  [items]="subjects"
  [titleLabel]="titleExtractor"
  [isSelected]="selectedExtractor"
  [status] = "statusExtractor"
  (edit)="editSubject($event)"
  (showContent)="selectSubject($event)"
>
  <div title>
    Subjects
  </div>

  <div crumb>
    > Alphazed Admin Boss
  </div>

  <div secondary>
    <div class="subject-pane">
      <app-form-header
        title="A Subject"
        subtitleWhenExist="Creating"
        subtitleWhenNotExist="Editing"
        [obj]="selectedSubject"
      ></app-form-header>

      <app-main-form
        [mediaConfig]="mediaConfig"
        [formValue]="selectedSubject"
        (save)="onSave($event)"
        (generateDynamicLink)="generateDynamicLink()"
        [dynamicLink]="dynamicLink"
        [subjectLevelVisible] = true
      ></app-main-form>
    </div>
  </div>
</app-sortable-cards-viewer>
