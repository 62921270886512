
<mat-card [class.selected]="selected">
  <div
  class="header-bar"
  [ngClass]="{
    'inActive': !this.isActive
  }"
></div>

  <mat-card-header>
    <mat-card-subtitle>
      <mat-chip-list *ngIf="tags" class="tags" disabled>
        <mat-chip *ngFor="let tag of tags">{{tag}}</mat-chip>
      </mat-chip-list>

      <div fxLayoutAlign="end start">
        <app-stroked-button class="button" (onClick)="onCopy($event)" >Copy</app-stroked-button>

        <ng-container *ngIf="selected; else notEditing">
          <app-button class="button">Editing</app-button>
        </ng-container>

        <ng-template #notEditing>
          <app-stroked-button class="button" (onClick)="onEdit($event)">Edit</app-stroked-button>
        </ng-template>
      </div>

    </mat-card-subtitle>

    <mat-card-title class="title" fxLayoutAlign="start start" dir="{{textDirection}}">

      <div fxLayout="row" fxLayoutAlign="space-between" class="preview">
        <span class="preview-text" *ngIf="title else emptyFiller" [innerHTML]="title | safeHtml"></span>

        <ng-template #emptyFiller>
          <span></span>
        </ng-template>

        <app-s3-image-file *ngIf="imageUrl" [fileName]="imageUrl" class="preview-img"></app-s3-image-file>
      </div>

    </mat-card-title>
  </mat-card-header>
  <mat-chip-list disabled>
    <mat-chip  disabled>{{id}}</mat-chip>
  </mat-chip-list>
</mat-card>
