import {Component, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageFile} from '../../models/image-file.model';
import {Input} from '../../models/input.model';
import {AudioFilesComponent} from '../audio-files/audio-files.component';
import {VideoFilesComponent} from '../video-files/video-files.component';
import {S3Service} from '../../services/s3.service';
import {BucketFoldersInjectNames} from '../../../content-bytes/inject-names';
import {ImageFilesComponent} from '../image-files/image-files.component';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  public selectedImage: ImageFile = null;

  @ViewChild(AudioFilesComponent) audios: AudioFilesComponent;
  @ViewChild(ImageFilesComponent) images: ImageFilesComponent;
  @ViewChild(VideoFilesComponent) videos: VideoFilesComponent;
  private s3: S3Service;

  constructor(
    private injector: Injector,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Input) {
    this.selectedImage = data?.selected_image ?? null;

    this.s3 = this.injector.get<S3Service>(this.injectName as any);
  }

  get title(): string {
    switch (this.injectName) {
      case BucketFoldersInjectNames.AUDIOS:
        return 'Audio Files';

      case BucketFoldersInjectNames.IMAGES:
        return 'Image Files';

      case BucketFoldersInjectNames.VIDEOS:
          return 'Video Files';
      default:
        break;
    }
  }

  public get isAudioDialog(): boolean {
    return this.injectName === BucketFoldersInjectNames.AUDIOS;
  }

  public get isVideoDialog(): boolean {
    return this.injectName === BucketFoldersInjectNames.VIDEOS;
  }

  public get isImageDialog(): boolean {
    return this.injectName === BucketFoldersInjectNames.IMAGES;
  }

  public get injectName(): BucketFoldersInjectNames {
    return this.data.injectName;
  }

  public get isSelectButtonDisabled(): boolean {
    return this.selectedImage == null;
  }

  ngOnInit(): void {
  }

  selectImage(imageFile: ImageFile): void {
    this.selectedImage = imageFile;
  }

  selectClicked(): void {
    this.dialogRef.close({selected_image: this.selectedImage});
  }

  newFileUploaded(file: ImageFile): void {
    if (this.audios) {
      this.audios.push(file);
    }

    if (this.images) {
      this.images.push(file);
    }

    if (this.videos) {
      this.videos.push(file);
    }
  }
}
