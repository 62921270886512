<app-sortable-tiles-viewer
  (create)="createNewContentBit($event)"
  (save)="saveOrder($event)"
  [imageUrlLabel]="imageUrlExtractor"
  [items]="contentBits"
  [titleLabel]="titleExtractor"
  [isSelected]="selectedExtractor"
  [status] = "statusExtractor"
  [tagsLabel]="tagsExtractor"
  [textDirection]="textDirectionExtractor"
  (edit)="editContentBit($event)"
  (copyItem) = "copyContentBit($event)"
>
  <div title>
    Listing ContentBits
  </div>

  <div crumb>
    > {{contentByte?.type }} > {{contentByte?.media?.text}}
  </div>

  <div secondary>
    <div>
      <app-form-header
        title="A Content Bit"
        subtitleWhenExist="Creating"
        subtitleWhenNotExist="Editing"
        [obj]="selectedContentBit"
      ></app-form-header>

      <app-content-bit-form
        [contentBit]="selectedContentBit"
        [contentByte]="contentByte"
        [isNewContentByte]="isNewContentByte"
        (save)="updateContentBit($event)"
      ></app-content-bit-form>
    </div>
  </div>
</app-sortable-tiles-viewer>
