import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MediaConfig } from '../../../content-bits/config/media.config';
import {
  contentByteMediaConfig,
  contentByteNotificationConfig,
} from '../../utils/media-configs';
import { defaultSelectedStatus } from '../../../../shared/components/status-select/status-select.component';
import {
  defaultSelectedPayLevel,
  payLevel,
} from 'src/app/shared/components/pay-level-select/pay-level-select.component';
import {
  defaultSelectedPlayMode,
  playMode,
} from '../play-mode-select/play-mode-select.component';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';

export const skillType = { title: 'Skill', value: 'skill' };
export const storyType = { title: 'Story', value: 'story' };
export const interactiveStoryType = {
  title: 'Interactive Story',
  value: 'interactive_story',
};

@Component({
  selector: 'app-content-byte-form',
  templateUrl: './content-byte-form.component.html',
  styleUrls: ['./content-byte-form.component.scss'],
})
export class ContentByteFormComponent implements OnInit, OnChanges {
  @Input()
  formValue: any;

  @Output()
  save = new EventEmitter();

  @Output()
  generateDynamicLink = new EventEmitter();

  @Output()
  resetCreatedDate = new EventEmitter();

  @Output()
  regenerateTTS = new EventEmitter();

  @Input()
  mediaConfig: MediaConfig = contentByteMediaConfig;

  notificationConfig: MediaConfig = contentByteNotificationConfig;

  form: FormGroup;

  contentByteTypes = [skillType, storyType, interactiveStoryType];

  @Input()
  dynamicLink: string = null;

  constructor(private fb: FormBuilder , private authService: AuthService) {
    this.form = this.fb.group({
      media: [],
      status: [this.defaultStatus],
      type: [this.defaultType],
      pay_level: [this.defaultPayLevel],
      play_mode: [this.defaultPlayMode],
      notification_message: [],
      title:[],
      hide_title:[false],
      subtitle:[],
      small_icon:[],
      info:[],
      admin_info:[],
      // language:['AR'],
    });
  }

  get languages(): string[] {
    return this.authService.languages;
  }

  get defaultLanguage(): String {
    return 'AR';
  }

  get defaultStatus(): string {
    return defaultSelectedStatus;
  }

  get defaultType(): string {
    return skillType.value;
  }

  get defaultPayLevel(): payLevel {
    return defaultSelectedPayLevel;
  }

  get defaultPlayMode(): playMode {
    return defaultSelectedPlayMode;
  }

  ngOnInit(): void {
    this.updateForm();
    this.form.controls.type.setValue(this.defaultType);
  }

  onSubmit($event: any): void {
    if (!this.form.value.notification_message?.text)
      this.form.value.notification_message = null;

    const { value, valid } = this.form;
    this.save.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.reset({
      pay_level: this.defaultPayLevel,
      status: this.defaultStatus,
      play_mode: this.defaultPlayMode,
      // language:this.defaultLanguage,
      hide_title:false
    });

    if (changes.formValue) {
      this.updateForm();
    }

    this.form.markAsPristine();
  }

  onGenerateDynamicLink(): void {
    this.generateDynamicLink.emit();
  }

  private updateForm(): void {
    if (this.formValue) {
      this.form.patchValue(this.formValue);
    }
  }
}
