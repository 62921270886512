import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {S3Service} from '../../services/s3.service';
import {ImageFile} from '../../models/image-file.model';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BucketFoldersInjectNames} from '../../../content-bytes/inject-names';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-audio-files',
  templateUrl: './audio-files.component.html',
  styleUrls: ['./audio-files.component.scss']
})
export class AudioFilesComponent implements OnInit {

  @Output()
  public onSelect = new EventEmitter<ImageFile>();

  public selected: ImageFile = null;
  public files: ImageFile[] = [];

  public query = '';
  public isLoading = false;

  isLoadingSubscriptions: Subscription;
  filesSubscriptions: Subscription;
  lastQuerySubscription: Subscription;

  constructor(@Inject(BucketFoldersInjectNames.AUDIOS) public s3: S3Service) {
    this.isLoadingSubscriptions = this.s3.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });

    this.filesSubscriptions = this.s3.files.subscribe((files) => {
      this.files = files;
    });

    this.lastQuerySubscription = this.s3.lastQuery.subscribe((query) => {
      this.query = query;
    });
  }

  trackFunc(index: number, image: ImageFile): string {
    return image.name;
  }

  ngOnInit(): void {
  }

  selectCard(image: ImageFile): void {
    this.toggleSelection(image);

    this.onSelect.emit(this.selected);
  }

  search(query: string): void {
    this.s3.fetchAllFiles(query);
  }

  push(file: ImageFile): void {
    this.files.unshift(file);
  }

  refresh(): void {
    this.search(this.query);
  }

  select(imageFile: ImageFile): void {
    this.onSelect.emit(imageFile);
  }

  private toggleSelection(image: ImageFile): void {
    if (image === this.selected) {
      this.selected = null;
    } else {
      this.selected = image;
    }
  }
}
