import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Splitter} from '@syncfusion/ej2-angular-layouts';
import {AuthService} from '../../../modules/authentication/services/auth.service';

@Component({
  selector: 'app-splitter',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.scss']
})
export class SplitterComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  showForm = false;

  @ViewChild('splitter')
  splitter: Splitter;

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.splitter && changes.showForm) {
      this.toggleFormVisibility();
    }
  }

  ngOnInit(): void {
  }

  goToHome(): void {
    this.router.navigate(['/']);
  }

  goBack(): void {
    this.location.back();
  }

  ngAfterViewInit(): void {
  }

  logout(): void {
    this.authService.logout();
  }

  private toggleFormVisibility(): void {
    if (!this.splitter) {
      return;
    }

    if (this.showForm) {
      this.splitter.expand(1);
    } else {
      this.splitter.collapse(1);
    }
  }
}
