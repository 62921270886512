import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BearerHttpClient {
  authorizationHeaderName = 'Authorization';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  get bearerHeader(): string {
    return 'Bearer ' + this.authService.tokenModel.token;
  }

  get bearerHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        Authorization: this.bearerHeader,
      }
    );
  }

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {headers: this.bearerHeaders});
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body, {headers: this.bearerHeaders});
  }

  public put<T>(url: string, body: T): Observable<T> {
    return this.http.put<T>(url, body, {headers: this.bearerHeaders});
  }

  private addBearerHeader(headers: HttpHeaders = null): HttpHeaders {
    if (!!headers) {
      return headers.append(this.authorizationHeaderName, this.bearerHeader);
    }

    return this.bearerHeaders;
  }
}
