import {MediaConfig} from '../config/media.config';

export const defaultDidYouKnowConfig: MediaConfig = {
  text: true,
  image_url: false,
  audio_url: true,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};
