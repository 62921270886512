import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {defaultTtsVoiceName} from '../tts-voice-name-select/tts-voice-name-select.component';
import {TashkeelService} from '../../services/tashkeel.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-tts-form',
  templateUrl: './tts-form.component.html',
  styleUrls: ['./tts-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtsFormComponent),
      multi: true,
    }
  ],
})
export class TtsFormComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private tashkeelService: TashkeelService,
  ) {
  }

  @Input()
  layoutAlignment = 'right';

  @Input()
  textDirection = 'rtl';

  get ttsAudioUrlControl(): FormControl {
    return this.form.controls.audio_url as FormControl;
  }

  get textControl(): FormControl {
    return this.form.controls.text as FormControl;
  }

  get speechMarksControl(): FormControl {
    return this.form.controls.speech_marks as FormControl;
  }

  get speechMarkList(): [any] {
    return this.speechMarksControl.value;
  }

  get ttsAudioUrl(): string {
    return this.ttsAudioUrlControl.value;
  }

  get text(): string {
    return this.textControl.value;
  }

  get isEmptyText(): boolean {
    return !this.text?.trim()?.length;
  }

  get isDirtyControl(): FormControl {
    return this.form.controls.is_dirty as FormControl;
  }

  get isDirty(){
    return this.isDirtyControl.value;
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  speechMarks = [];

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.form.reset({voice_name: defaultTtsVoiceName});

    if (obj) {
      this.form.patchValue(obj);
      this._setupSpeechMarks();
      console.log(this.speechMarks);
    }
  }

  ngOnInit(): void {
    this.rebuildForm();
  }

  private _setupSpeechMarks(){
    this.speechMarks = this.speechMarkList?.map( sm => {
        return this.text.substring(sm.start_index, sm.start_index + sm.length);
    }) ?? [];
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  shakkelFull(): void {
    this.tashkeelService.tashkeel({text: this.text, reduced: false})
      .pipe(take(1))
      .subscribe((response) => {
        this.textControl.setValue(response.text);
      });
  }

  shakkelReduced(): void {
    this.tashkeelService.tashkeel({text: this.text, reduced: true})
      .pipe(take(1))
      .subscribe((response) => {
        this.textControl.setValue(response.text);
      });
  }

  stripTashkeel(): void {
    this.textControl.setValue(this.tashkeelService.strip(this.text));
  }



  private rebuildForm(): void {
    this.form = this.fb.group({
      text: [],
      voice_name: [defaultTtsVoiceName],
      audio_url: [],
      speech_marks:[],
      is_dirty:[]
    });
  }
}
