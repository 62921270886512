import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {SplitterModule} from '@syncfusion/ej2-angular-layouts';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatGridListModule} from '@angular/material/grid-list';
import {SortablejsModule} from 'ngx-sortablejs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DropDownComponent} from './components/drop-down/drop-down.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {ColorPickerComponent} from './components/color-picker/color-picker.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {CardComponent} from './components/card/card.component';
import {MatChipsModule} from '@angular/material/chips';
import {SplitterComponent} from './components/splitter/splitter.component';
import {ButtonComponent} from './components/button/button.component';
import {StrokedButtonComponent} from './components/stroked-button/stroked-button.component';
import {ActionsComponent} from './components/actions/actions.component';
import {MainActionsComponent} from './components/main-actions/main-actions.component';
import {SortableCardsViewerComponent} from './components/sortable-cards-viewer/sortable-cards-viewer.component';
import {TruncatePipe} from './pipes/truncate.pipe';
import {TileComponent} from './components/tile/tile.component';
import {SortableTilesViewerComponent} from './components/sortable-tiles-viewer/sortable-tiles-viewer.component';
import {ClickStopPropagationDirective} from './directives/click-stop-propagation.directive';
import {ProgressSpinnerComponent} from './components/progress-spinner/progress-spinner.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SnackBarComponent} from './components/snack-bar/snack-bar.component';
import {HttpClientModule} from '@angular/common/http';
import {StatusSelectComponent} from './components/status-select/status-select.component';
import {S3ImageFileComponent} from './components/s3-image-file/s3-image-file.component';
import {S3AudioFileComponent} from './components/s3-audio-file/s3-audio-file.component';
import {AudioFileComponent} from './components/audio-file/audio-file.component';
import {ImageFileComponent} from './components/image-file/image-file.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FilePickerDirective} from './directives/file-picker.directive';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { PayLevelSelectComponent } from './components/pay-level-select/pay-level-select.component';
import {NgxTinymceModule} from 'ngx-tinymce';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import { VideoFileComponent } from './components/video-file/video-file.component';
import { S3VideoFileComponent } from './components/s3-video-file/s3-video-file.component';


const modules = [
  CommonModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  ReactiveFormsModule,
  MatInputModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatCardModule,
  SplitterModule,
  DragDropModule,
  MatGridListModule,
  SortablejsModule,
  FormsModule,
  MatTooltipModule,
  MatSelectModule,
  NgSelectModule,
  MatCheckboxModule,
  FlexLayoutModule,
  NgbModule,
  NgbDropdownModule,
  ColorPickerModule,
  MatChipsModule,
  MatSnackBarModule,
  HttpClientModule,
  VgCoreModule,
  VgControlsModule,
  VgOverlayPlayModule,
  VgBufferingModule,
  MatButtonToggleModule,
  MatSlideToggleModule,
  ClipboardModule,
  // EditorModule,
];


@NgModule({
  declarations: [
    DropDownComponent,
    ColorPickerComponent,
    ConfirmationDialogComponent,
    CardComponent,
    SplitterComponent,
    ButtonComponent,
    StrokedButtonComponent,
    ActionsComponent,
    MainActionsComponent,
    SortableCardsViewerComponent,
    SortableTilesViewerComponent,
    TruncatePipe,
    SafeHtmlPipe,
    TileComponent,
    ClickStopPropagationDirective,
    FilePickerDirective,
    ProgressSpinnerComponent,
    SnackBarComponent,
    StatusSelectComponent,
    S3ImageFileComponent,
    S3AudioFileComponent,
    AudioFileComponent,
    ImageFileComponent,
    PayLevelSelectComponent,
    VideoFileComponent,
    S3VideoFileComponent,
  ],
  imports: [
    ...modules,
    NgxTinymceModule.forRoot({
      baseURL: '/assets/tinymce/',
      config: {
        menu: {
        },
        plugins: 'textcolor',
        toolbar: 'forecolor backcolor styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
      }
    }),
  ],
  exports: [
    ...modules,
    NgxTinymceModule,
    DropDownComponent,
    ColorPickerComponent,
    ConfirmationDialogComponent,
    CardComponent,
    SplitterComponent,
    ButtonComponent,
    StrokedButtonComponent,
    ActionsComponent,
    MainActionsComponent,
    SortableCardsViewerComponent,
    SortableTilesViewerComponent,
    TruncatePipe,
    SafeHtmlPipe,
    ClickStopPropagationDirective,
    FilePickerDirective,
    ProgressSpinnerComponent,
    StatusSelectComponent,
    S3ImageFileComponent,
    S3AudioFileComponent,
    S3VideoFileComponent,
    AudioFileComponent,
    ImageFileComponent,
    VideoFileComponent,
    PayLevelSelectComponent,
  ]
})
export class SharedModule {
}
