<form [formGroup]="form" class="container">
  <div class="form-group row" *ngIf="config.font">
    <app-font-options-form
      formControlName="font"
      [config]="config.font"
    ></app-font-options-form>
  </div>

  <div class="form-group row" *ngIf="config.ken_burns">
    <div class="col-sm-12">
      <mat-slide-toggle formControlName="ken_burns" color="primary">
        Ken burns
      </mat-slide-toggle>
    </div>
  </div>

  <div class="form-group row" *ngIf="config.shake">
    <div class="col-sm-12">
      <mat-slide-toggle formControlName="shake" color="primary">
        Shake
      </mat-slide-toggle>
    </div>
  </div>

  <div class="form-group row" *ngIf="config.image_fit">
    <div class="col-sm-2">image fit</div>
    <div class="col-sm-10">
      <app-image-fit-select formControlName="image_fit"></app-image-fit-select>
    </div>
  </div>

  <div  *ngIf="config.shadow">
    <div class="form-group row">
      <div class="col-sm-2">
        <label>shadow</label>
      </div>
      <div class="col-sm-10">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="isShadowEnable"
            [ngModelOptions]="{standalone: true}"
            (change)="onShadowEnableChange()"
          />
        </div>
      </div>
    </div>

    <div formGroupName="shadow" *ngIf="isShadowEnable">
      <div class="form-group row">
        <div class="col-sm-2">shadow color</div>
        <div class="col-sm-10">
          <app-color-picker formControlName="color"></app-color-picker>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row" *ngIf="config.top_coordinate">
    <div class="col-sm-2">Top coordinate</div>
    <div class="col-sm-10">
      <input
        type="number"
        class="form-control"
        formControlName="top_coordinate"
      />
    </div>
  </div>

  <div class="form-group row" *ngIf="config.left_coordinate">
    <div class="col-sm-2">Left coordinate</div>
    <div class="col-sm-10">
      <input
        type="number"
        class="form-control"
        formControlName="left_coordinate"
      />
    </div>
  </div>

  <div class="form-group row" *ngIf="config.width">
    <div class="col-sm-2">Width</div>
    <div class="col-sm-10">
      <input type="number" class="form-control" formControlName="width" />
    </div>
  </div>

  <div class="form-group row" *ngIf="config.height">
    <div class="col-sm-2">Height</div>
    <div class="col-sm-10">
      <input type="number" class="form-control" formControlName="height" />
    </div>
  </div>

  <div class="form-group row" *ngIf="config.tracing_shape">
    <div class="col-sm-2">shape</div>
    <div class="col-sm-10">
      <app-tracing-shapes-select formControlName="tracing_shape"></app-tracing-shapes-select>
    </div>
  </div>

</form>
