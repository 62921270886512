import {AfterViewInit, Component, forwardRef, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

export enum payLevel {
  l1_pay_full = 'l1_pay_full',
  l3_trial = 'l3_trial',
  l5_free_partial = 'l5_free_partial',
  l10_free = 'l10_free',
}

export const defaultSelectedPayLevel = payLevel.l10_free;

@Component({
  selector: 'app-pay-level-select',
  templateUrl: './pay-level-select.component.html',
  styleUrls: ['./pay-level-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PayLevelSelectComponent),
      multi: true,
    }
  ],
})
export class PayLevelSelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  form: FormGroup;
  allPayLevel = [
    {title: 'l1 pay full', value: payLevel.l1_pay_full, },
    {title: 'l3 trial', value: payLevel.l3_trial, },
    {title: 'l5 free partial', value: payLevel.l5_free_partial, },
    {title: 'l10 free', value: payLevel.l10_free, },
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      pay_level: [this.defaultPayLevel],
    });
  }

  get defaultPayLevel(): string {
    return defaultSelectedPayLevel;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pay_level) {
      this.form.patchValue({
        pay_level: changes.pay_level
      });
    }

    this.form.markAsPristine();
  }

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((x) => {
      this.onChange(x.pay_level);
      this.onTouch(x.pay_level);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue({pay_level: val});
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
