import {Injectable} from '@angular/core';
import * as AWS from 'aws-sdk';
import {search} from 'jmespath';
import {HeadObjectOutput, Object} from 'aws-sdk/clients/s3';
import {ImageFile} from '../models/image-file.model';
import {BucketConfigModel} from '../../../core/models/bucket-config.model';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../../authentication/services/auth.service';
// tslint:disable:ban-types

type FolderKey = 'audios_folder' | 'images_folder' | 'excel_folder' | 'videos_folder';

@Injectable()
export class S3Service {
  static SEPARATOR = '/';

  public isLoading = new BehaviorSubject<boolean>(true);
  public files = new BehaviorSubject<ImageFile[]>([]);
  public lastQuery = new BehaviorSubject<string>('');

  private bucketConfig: BucketConfigModel;
  private s3: AWS.S3;

  constructor(
    private authService: AuthService,
    private folderKey: FolderKey
  ) {
    this.bucketConfig = this.authService.bucketConfig;

    this.s3 = new AWS.S3({
      params: {
        Bucket: this.bucketConfig.bucket_name,
      },
      region: this.bucketConfig.region,
      credentials: {
        accessKeyId: this.bucketConfig.access_key_id,
        secretAccessKey: this.bucketConfig.secret_access_key,
      }
    });
  }

  public get cloudFrontUrl(): string {
    return this.bucketConfig.cloudfront_url;
  }

  public get prefix(): string {
    return this.authService.bucketConfig[this.folderKey];
  }

  public objectUrl(fileName): string {
    return this.cloudFrontUrl + this.prefix + fileName;
  }

  public exists(fileName: string, extension: string): Promise<HeadObjectOutput> {
    return new Promise((resolve, reject) => {
      this.s3.headObject({
        Bucket: this.bucketConfig.bucket_name,
        Key: `${this.prefix}${fileName}.${extension}`,
      }, (e, d) => {
        if (e) {
          reject(e);
          return;
        }

        resolve(d);
      });
    });
  }

  public async uploadFile(file, fileName: string, extension: string): Promise<ImageFile> {
    const uploadedFile = await new AWS.S3.ManagedUpload({
      params: {
        Body: file,
        Key: `${this.prefix}${fileName}.${extension}`,
        Bucket: this.bucketConfig.bucket_name,
      },
      service: this.s3,
    }).promise();

    return {
      name: this.actualFileName(uploadedFile),
    };
  }

  public async fetchAllFiles(query: string): Promise<ImageFile[]> {
    this.isLoading.next(true);
    this.lastQuery.next(query);

    let list: Object[] = [];
    let shouldContinue = true;
    let nextContinuationToken = null;
    while (shouldContinue) {
      const res = await this.s3
        .listObjectsV2({
          Bucket: this.bucketConfig.bucket_name,
          ContinuationToken: nextContinuationToken || undefined,
          Prefix: this.prefix,
        })
        .promise();

      const results: Object[] = search(res, `Contents[?contains(Key, \`${query}\`) && Size > '0' && starts_with(Key, '${this.prefix}')]`);
      list = [...list, ...results];

      if (!res.IsTruncated) {
        shouldContinue = false;
        nextContinuationToken = null;
      } else {
        nextContinuationToken = res.NextContinuationToken;
      }
    }
    const mappedList = list
      .map((item) => {
        return {
          name: this.actualFileName(item),
        };
      });

    this.isLoading.next(false);
    this.files.next(mappedList);

    return mappedList;
  }

  private actualFileName(item: Object): string {
    return item.Key.split(S3Service.SEPARATOR).slice(1).join(S3Service.SEPARATOR);
  }
}
