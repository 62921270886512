<form [formGroup]="form" class="form">
  <div class="actions">
    <div class="form-group row" fxLayout="row" fxLayoutAlign="end">
      <app-button (onClick)="onSubmit()" [disabled]="!form.dirty">Save</app-button>
    </div>
  </div>

  <div class="info">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Status</label>
      <div class="col-sm-10">
        <app-status-select formControlName="status"></app-status-select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Question Type</label>
      <div class="col-sm-10">
        <ng-select
          [items]="types"
          bindValue="name"
          bindLabel="title"
          formControlName="type"
          placeholder="Select type"
          (ngModelChange)="typeChanged($event)"
        >
        </ng-select>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="2%" *ngIf="selectedTypeConfig">

      <app-properties-form
        formControlName="properties"
        [config]="selectedTypeConfig?.properties_config"
        *ngIf="selectedTypeConfig.properties_config"
      ></app-properties-form>

      <app-media-form
        formControlName="pre_text"
        title="Pre Text"
        [config]="selectedTypeConfig?.pre_text_config"
        *ngIf="selectedTypeConfig?.pre_text"
      ></app-media-form>

      <app-media-form
        formControlName="main_text"
        title="Main Text"
        [config]="selectedTypeConfig?.main_text_config"
        *ngIf="selectedTypeConfig?.main_text"
      ></app-media-form>

      <app-media-form
        formControlName="tip_text"
        title="Tip Text"
        [config]="selectedTypeConfig?.tip_text_config"
        *ngIf="selectedTypeConfig?.tip_text"
      ></app-media-form>

      <app-media-form
        formControlName="did_you_know"
        title="Did You Know"
        [config]="selectedTypeConfig?.did_you_know_config"
        *ngIf="selectedTypeConfig?.did_you_know"
      ></app-media-form>
    </div>
  </div>

  <div class="answers" *ngIf="selectedTypeConfig?.has_answers">
    <app-answers-form
      formControlName="answers"
      [config]="selectedTypeConfig?.answers_config"
    ></app-answers-form>
  </div>


  <div class="actions">
    <div class="form-group row" fxLayout="row" fxLayoutAlign="end">
      <app-button (onClick)="onSubmit()" [disabled]="!form.dirty">Save</app-button>
    </div>
  </div>
</form>
