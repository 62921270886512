import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-file',
  templateUrl: './image-file.component.html',
  styleUrls: ['./image-file.component.scss']
})
export class ImageFileComponent implements OnInit {

  @Input()
  fileUrl: string;

  @Input()
  height: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
