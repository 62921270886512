<form [formGroup]="form">
  <ng-select
    [items]="allPayLevel"
    [clearable]="false"
    [ngModel]="defaultPayLevel"
    bindLabel="title"
    bindValue="value"
    formControlName="pay_level"
    placeholder=""
  ></ng-select>
</form>
