import {Component, Inject, OnInit} from '@angular/core';
import {ContentByteModel} from "../../models/content-byte.model";
import {ContentBitModel} from "../../../content-bits/models/content-bit.model";
import {InjectNames} from "../../inject-names";
import {ItemControllerService} from "../../../../shared/services/item-controller.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-content-byte',
  templateUrl: './content-byte.component.html',
  styleUrls: ['./content-byte.component.scss']
})
export class ContentByteComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    @Inject(InjectNames.CONTENT_BYTE_ITEM) private itemController: ItemControllerService<ContentByteModel, ContentBitModel>,
  ) {
    this.itemController.fetch(this.route.snapshot.params.id);
  }

  get selectedContentBit(): ContentBitModel {
    return this.itemController.selected;
  }

  get contentBits() {
    return this.itemController.items;
  }

  get contentByte() {
    return this.itemController.item;
  }

  get isNewContentByte(): boolean {
    return this.itemController.items.length == 0;
  }

  ngOnInit(): void {
    this.itemController.unselect();
  }

  createNewContentBit($event: MouseEvent) {
    this.itemController.unselect();
  }

  saveOrder(items: any[]) {
    this.itemController.reorder(items);
  }

  editContentBit(contentBit: ContentBitModel) {
    this.itemController.select(contentBit);
  }

  imageUrlExtractor = (item: ContentBitModel) => {
    return item?.main_text?.image_url;
  }

  titleExtractor = (item: ContentBitModel) => {
    return item?.main_text?.text;
  }

  textDirectionExtractor = (item: ContentBitModel) => {
    return item?.main_text?.text_direction;
  }

  statusExtractor = (item: ContentBitModel) => {
    return item?.status ?? 'active';
  }

  selectedExtractor = (item: ContentBitModel) => {
    return item.id === this.selectedContentBit?.id || false;
  }

  tagsExtractor = (item: ContentBitModel) => {
    return [item?.type].filter((tag) => tag);
  }

  updateContentBit(contentBit: ContentBitModel) {
    this.itemController.save({...contentBit, content_byte_id: this.contentByte?.id});
  }

  copyContentBit(contentBit: ContentBitModel) {
    this.itemController.select(contentBit);
    this.itemController.copy();
  }

}
