<div class="form-group row">
  <label class="col-sm-2 col-form-label">{{label}}</label>
  <div class="input-group col-sm-10">
    <input type="text" class="form-control" [formControl]="url">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="clearValue()" type="button">
        <mat-icon>close</mat-icon>
      </button>
      <button class="btn btn-outline-secondary" (click)="openMediaLib()" type="button">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="form-group row">
  <div class="input-group col-sm-12">
    <app-s3-video-file [fileName]="url.value" class="full-wid"></app-s3-video-file>
  </div>
</div>
