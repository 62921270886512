import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayControllerService {
  constructor() {
  }

  private _isShown: boolean = true;

  get isShown() {
    return this._isShown;
  }

  show() {
    this._isShown = true;
  }

  hide() {
    this._isShown = false;
  }
}
