import {Injectable} from '@angular/core';
import {ListControllerService} from './list-controller.service';
import {take} from 'rxjs/operators';
import {ApiManagerService} from './api-manager.service';
import {Observable} from 'rxjs';
import {DynamicLinkResponseModel} from '../../modules/content-bytes/models/dynamic-link-response.model';

interface X {
  id?: number;
}

@Injectable()
export class ItemControllerService<P extends X, C extends X> {
  private listController: ListControllerService<C>;

  constructor(
    public api: ApiManagerService<P>,
    childApi: ApiManagerService<C>,
    private childKey: string,
  ) {
    this.listController = new ListControllerService<C>(childApi);
  }

  // tslint:disable-next-line:variable-name
  private _item: P = null;

  get item(): P {
    return this._item;
  }

  set item(item) {
    this._item = item;
  }


  get items(): C[] {
    return this.listController.items;
  }

  set items(items) {
    this.listController.items = items;
  }

  get selected(): C {
    return this.listController.selected;
  }

  set selected(selected) {
    this.listController.selected = selected;
  }

  fetch(id: number): void {
    this.api.fetch(id).pipe(take(1)).subscribe(
      (data) => {
        this.item = data;
        this.items = data[this.childKey];
      }
    );
  }

  reorder(items: any[]): void {
    this.listController.reorder(this._item.id, items);
  }

  unselect(): void {
    this.listController.unselect();
  }

  public select(item: C): void {
    this.listController.select(item);
  }

  save(value: any): void {
    this.listController.save(value);
  }

  regenerateTTS(): any {
    return this.listController.regenerateTTS();
  }

  copy(): any {
    return this.listController.copy();
  }

  generateDynamicLink(): Observable<DynamicLinkResponseModel> {
    return this.listController.generateDynamicLink();
  }

  resetCreatedDate(): any {
    return this.listController.resetCreatedDate()
  }
}
