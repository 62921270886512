import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChapterModel } from '../../models/chapter.model';
import { ContentByteModel } from '../../models/content-byte.model';
import { ItemControllerService } from '../../../../shared/services/item-controller.service';
import { BucketFoldersInjectNames, InjectNames } from '../../inject-names';
import { ActionNames } from '../../action-names';
import { take } from 'rxjs/operators';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { MessageResponseModel } from '../../models/message-response.model';
import { S3Service } from '../../../media-lib/services/s3.service';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss'],
})
export class ChapterComponent implements OnInit {
  dynamicLink: string = null;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    @Inject(InjectNames.CHAPTER_ITEM)
    private itemController: ItemControllerService<
      ChapterModel,
      ContentByteModel
    >,
    @Inject(BucketFoldersInjectNames.EXCEL) public s3: S3Service
  ) {
    this.itemController.fetch(this.route.snapshot.params.id);
  }

  get chapter(): ChapterModel {
    return this.itemController.item;
  }

  get contentBytes(): ContentByteModel[] {
    return this.itemController.items;
  }

  get selectedContentByte(): ContentByteModel {
    return this.itemController.selected;
  }

  imageUrlExtractor = (item: ContentByteModel) => {
    return item.media?.image_url;
  };

  titleExtractor = (item: ContentByteModel) => {
    return item.media?.text;
  };

  selectedExtractor = (item: ContentByteModel) => {
    return item.id === this.selectedContentByte?.id || false;
  };

  tagsExtractor = (item: ContentByteModel) => {
    return [item?.type?.valueOf()].filter((tag) => tag);
  };

  statusExtractor = (item: ContentByteModel) => {
    return item?.status ?? 'active';
  };

  ngOnInit(): void {
    this.itemController.unselect();
  }

  createNewContentByte(_: MouseEvent): void {
    this.dynamicLink = null;
    this.itemController.unselect();
  }

  saveOrder(items: any[]): void {
    this.itemController.reorder(items);
  }

  async selectContentByte(obj): Promise<void> {
    var $event = obj.$event;
    if (
      $event.metaKey ||
      $event.ctrlKey ||
      $event.keyCode == 91 ||
      $event.keyCode == 224
    ) {
      const url = this.router.createUrlTree(['content-bytes/', obj.item.id]);
      window.open(url.toString(), '_blank');
    } else {
      await this.router.navigate(['content-bytes/', obj.item.id]);
    }
  }

  editContentByte(contentByte: ContentByteModel): void {
    this.dynamicLink = null;
    this.itemController.select(contentByte);
  }

  copyContentByte(contentByte: ContentByteModel): void {
    this.dynamicLink = null;
    this.itemController.select(contentByte);
    this.itemController.copy();
  }

  onSave(value: any): void {
    this.itemController.save({ ...value, chapter_id: this.chapter?.id });
  }

  async importExcelSheet(files: FileList): Promise<void> {
    if (files.length <= 0) {
      return;
    }

    const file = files.item(0);
    const extension = file.name.split('.').pop();
    const fileName = file.name.substr(0, file.name.lastIndexOf('.'));

    await this.s3.uploadFile(file, fileName, extension);

    this.itemController.api
      .import<MessageResponseModel>(ActionNames.SAVE_CHAPTER_WORKBOOK, {
        file_name: file.name,
        chapter_id: this.chapter.id,
      })
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.snackBar.openSnackBar(
            {
              title: response.status,
              html: response.message,
            },
            response.status,
            undefined,
            -1
          );
          if (response.status == 'success')
            setTimeout(() => {
              this.reloadCurrentRoute();
            }, 1000);
        },
        (e) => {
          console.log(e);
        }
      );
  }

  generateDynamicLink(): void {
    this.itemController.generateDynamicLink().subscribe((value) => {
      this.dynamicLink = value.short_link;
    });
  }

  resetCreatedDate(): void {
    this.itemController.resetCreatedDate().subscribe();
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  regenerateTTS() {
    this.itemController.regenerateTTS();
  }
}
