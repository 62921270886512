import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input()
  type: string;

  @Input()
  disabled: boolean = false;

  @Input()
  cssClass: string;

  @Output()
  onClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onButtonClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.onClick.emit();
  }
}
