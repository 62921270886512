<ejs-splitter #horizontal width='100%' #splitter>
  <e-panes>
    <e-pane size='70%' cssClass="pane">
      <ng-template #content>

        <div class="header" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%">
          <div>

            <div class="crumb">
              <ng-content select="[crumb]" class="crumb"></ng-content>
            </div>

            <div class="title">
              <ng-content select="[title]" class="title"></ng-content>
            </div>

          </div>

          <app-actions>
            <app-stroked-button (onClick)="goBack()">Back</app-stroked-button>
            <app-stroked-button (onClick)="goToHome()">Home</app-stroked-button>
            <app-stroked-button (onClick)="logout()">Logout</app-stroked-button>
          </app-actions>

        </div>

        <div class="actions">
          <ng-content select="[actions]" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1%"></ng-content>
        </div>

        <ng-content select="[main]"></ng-content>
      </ng-template>
    </e-pane>
    <e-pane size='30%' cssClass="secondary-pane" [collapsed]="!showForm">
      <ng-template #content>

        <ng-content select="[secondary]"></ng-content>

      </ng-template>
    </e-pane>
  </e-panes>
</ejs-splitter>
