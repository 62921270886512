import {ContentBitFormComponent} from './components/content-bit-form/content-bit-form.component';
import {SharedModule} from '../../shared/shared.module';
import {MediaLibModule} from '../media-lib/media-lib.module';
import {MediaFormComponent} from './components/media-form/media-form.component';
import {NgModule} from '@angular/core';
import {AnswersFormComponent} from './components/answers-form/answers-form.component';
import {TtsVoiceNameSelectComponent} from './components/tts-voice-name-select/tts-voice-name-select.component';
import {TtsFormComponent} from './components/tts-form/tts-form.component';
import {MediaPropertiesFormComponent} from './components/media-properties-form/media-properties-form.component';
import {MatRadioModule} from '@angular/material/radio';
import { FontOptionsFormComponent } from './components/font-options-form/font-options-form.component';
import {PropertiesFormComponent} from './components/properties-form/properties-form.component';
import {CalculatorModifierFormComponent} from './components/calculator-modifier-form/calculator-modifier-form.component';
import { ImageFitSelectComponent } from './components/image-fit-select/image-fit-select.component';
import { TracingShapesSelectComponent } from './components/tracing-shapes-select/tracing-shapes-select.component';
import { SwitchTypeSelectComponent } from './components/switching/switch-type-select/switch-type-select.component';
import { SwitchSlotSelectComponent } from './components/switching/switch-slot-select/switch-slot-select.component';
import { MediaLanguageFormComponent } from './components/media-language-form/media-language-form.component';

const components = [
  ContentBitFormComponent,
  MediaFormComponent,
  AnswersFormComponent,
  TtsVoiceNameSelectComponent,
];

@NgModule({
  declarations: [
    ...components,
    TtsFormComponent,
    MediaPropertiesFormComponent,
    PropertiesFormComponent,
    FontOptionsFormComponent,
    CalculatorModifierFormComponent,
    ImageFitSelectComponent,
    TracingShapesSelectComponent,
    SwitchTypeSelectComponent,
    SwitchSlotSelectComponent,
    MediaLanguageFormComponent,
  ],
  imports: [
    SharedModule,
    MediaLibModule,
    MatRadioModule,
  ],
  exports: [
    ...components
  ]
})
export class ContentBitsModule {
}
