import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {InitCallGuard} from '../../../core/guards/init-call.guard';
import {IsLoggedInGuard} from './is-logged-in.guard';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
  constructor(
    private initGuard: InitCallGuard,
    private isLoggedInGuard: IsLoggedInGuard,
  ) {
  }

  private get orderedChildGuards(): CanActivateChild[] {
    return [
      this.initGuard,
      this.isLoggedInGuard,
    ];
  }

  private get orderedGuards(): CanActivate[] {
    return [
      this.initGuard,
      this.isLoggedInGuard,
    ];
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    for (const guard of this.orderedGuards) {
      if (await guard.canActivate(next, state) === false) {
        return false;
      }
    }
    return true;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    for (const guard of this.orderedChildGuards) {
      if (await guard.canActivateChild(next, state) === false) {
        return false;
      }
    }
    return true;
  }
}
