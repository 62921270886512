import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InjectNames } from 'src/app/modules/content-bytes/inject-names';
import { ListControllerService } from 'src/app/shared/services/list-controller.service';
import {TracingShapeModel} from "../../models/tracing-shape.model";

export const defaultTracingShapeId = '1';

@Component({
  selector: 'app-tracing-shapes-select',
  templateUrl: './tracing-shapes-select.component.html',
  styleUrls: ['./tracing-shapes-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TracingShapesSelectComponent),
      multi: true,
    }
  ],
})
export class TracingShapesSelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  tracingShape: FormControl;

  constructor(
    @Inject(InjectNames.TRACING_SHAPES) private listController: ListControllerService<TracingShapeModel>,
    private fb: FormBuilder,
    ) {
      this.tracingShape = this.fb.control(defaultTracingShapeId);
    }

    get shapes() {
      return this.listController.items;
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.image_fit) {
        this.tracingShape.patchValue(changes.image_fit);
      }

      this.tracingShape.markAsPristine();
    }

    onChange: any = () => {
    };

    onTouch: any = () => {
    };

    ngAfterViewInit(): void {
      setTimeout(() => {
      this.listController.fetchAll();
      }, 1
      );

      this.tracingShape.valueChanges.subscribe((x) => {
        this.onChange(x);
        this.onTouch(x);
      });
    }

    registerOnChange(fn: any): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
      this.onTouch = fn;
    }

    writeValue(val: any): void {
      if (val) {
        this.tracingShape.patchValue(val);
      }
    }

    setDisabledState(isDisabled: boolean): void {
      if (isDisabled) {
        this.tracingShape.disable();
      } else {
        this.tracingShape.enable();
      }
    }


}
