<form [formGroup]="form" class="container">

    <div class="form-group row" *ngIf="config.size_family">
      <div class="col-sm-12">
        <label>Font size</label>
        <mat-radio-group formControlName="size_family" color="primary" fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-radio-button value="default">Default</mat-radio-button>
          <mat-radio-button value="large">Large</mat-radio-button>
          <mat-radio-button value="extra-large">Extra Large</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

</form>
