import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss' , '/src/sass/shared.scss']
})
export class CardComponent implements OnInit {
  @Input()
  tags?: string[];

  @Input()
  id?: number;

  @Input()
  title?: string;

  @Input()
  image?: string;

  @Input()
  selected?: boolean;

  @Input()
  isActive?: boolean;

  @Input()
  showCopyButton?: boolean;

  @Output()
  edit = new EventEmitter();

  @Output()
  showContent = new EventEmitter();

  @Output()
  copyItem = new EventEmitter();



  get fileHeight(){
    return this.tags ? '70px' : '90px';
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onEdit($event: MouseEvent) {
    this.edit.emit();
  }

  onShowContent($event: MouseEvent) {
    this.showContent.emit($event);
  }

  onCopy($event: MouseEvent) {
    this.copyItem.emit();
  }

}
