import {MediaConfig} from '../../content-bits/config/media.config';

export const subjectMediaConfig: MediaConfig = {
  text: true,
  image_url: true,
  text_color: false,
  audio_url: false,
  is_correct: false,
  image_color: false,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  dynamic_links: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
};

export const conceptMediaConfig: MediaConfig = {
  text: true,
  image_url: true,
  text_color: false,
  audio_url: false,
  is_correct: false,
  image_color: false,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  language: true,
};

export const chapterMediaConfig: MediaConfig = {
  text: true,
  image_url: false,
  text_color: false,
  audio_url: false,
  is_correct: false,
  image_color: false,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  language: true,
};

export const contentByteMediaConfig: MediaConfig = {
  text: true,
  image_url: true,
  text_color: false,
  audio_url: false,
  is_correct: false,
  image_color: false,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  dynamic_links: true,
  language: true,
};

export const contentByteNotificationConfig: MediaConfig = {
  text: true,
  image_url: false,
  text_color: false,
  audio_url: false,
  is_correct: false,
  image_color: false,
  background_color: false,
  avatar_image_url: false,
  widget_type: false,
  dynamic_links: true,
};
