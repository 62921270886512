export interface FontOptions {
  size_family?: boolean;
}

export const defaultFontOptions: FontOptions = {
  size_family: false
};

export interface MediaPropertiesConfig {
  font?: FontOptions;
  ken_burns?: boolean;
  shake?: boolean;
  is_ken_burns_enabled?: boolean;
  left_coordinate?: boolean;
  top_coordinate?: boolean;
  width?: boolean;
  height?: boolean;
  image_fit?: boolean;
  shadow?: boolean;
  tracing_shape?:boolean
}

export const defaultPropertiesConfig: MediaPropertiesConfig = {
  font: {
    size_family: true
  },
  ken_burns: false,
  shake: false,
  is_ken_burns_enabled: false,
  width: false,
  top_coordinate: false,
  height: false,
  left_coordinate: false,
  image_fit: false,
  shadow: false,
  tracing_shape: false
};

export const spriteDragAndDropPropertiesConfig: MediaPropertiesConfig = {
  font: null,
  ken_burns: false,
  shake: false,
  is_ken_burns_enabled: false,
  width: false,
  top_coordinate: false,
  height: false,
  left_coordinate: false,
  image_fit: false,
  shadow: false
};
