export enum ContentBitType {
  TITLE = 'title',
  TRACING_SHAPE = 'tracing-shape',
  FRAGMENT = 'fragment',
  LIP_SYNC = 'lip-sync',
  VIDEO_SIMPLE = 'video-simple',
  SELECT_TEXT = 'select-text',
  SELECT_MEDIA = 'select-media',
  SENTENCE_BUILDING = 'sentence-building',
  PAIR_MATCHING_TEXT = 'pair-matching-text',
  PAIR_MATCHING_MEDIA = 'pair-matching-media',
  PAIR_MATCHING_MEDIA_FLIP = 'pair-matching-media-flip',
  FILL_IN_BLANKS = 'fill-in-blanks',
  OPEN = 'open',
  BUBBLE_POP = 'bubble-pop',
  BUBBLE_POP_FISH = 'bubble-pop-fish',
  COLORING_FREEFORM = 'coloring-freeform',
  CT_INFORMATIONAL = 'ct-informational',
  SPRITE_DRAG_AND_DROP = 'sprite-drag-and-drop',
  CALCULATOR = 'calculator',
  PUZZLE = 'puzzle',
  WORD_TRANSLATION = 'word-translation',
  WORD_BUILD = 'word-build',
  WORD_BUILD_CHILD_NAME = 'word-build-child-name',
  CLICK_TO_POP = 'click-to-pop',
  SPEAK_OUT_LOUD = 'speak-out-loud',
  SPEAK_OUT_LOUD_VIDEO = 'speak-out-loud-video',
  SPEAK_WITH_OVERLAY = 'speak-with-overlay',
  SWITCHING = 'switching',
  SLINGSHOT = 'slingshot'
}

