<form [formGroup]="form">
  <ng-select
    [items]="allPlayModes"
    [clearable]="false"
    [ngModel]="defaultPlayMode"
    bindLabel="title"
    bindValue="value"
    formControlName="play_mode"
    placeholder=""
  ></ng-select>
</form>
