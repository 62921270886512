
<!-- {{form.value | json}} -->
<div class="panel">
  <div
    class="header-bar"
    *ngIf="config.is_correct"
    [ngClass]="{
      'correct-answer': this.isCorrectSelected,
      'incorrect-answer': !this.isCorrectSelected
    }"
  ></div>
  <div
    class="header"
    (click)="collapseOrOpen()"
    [class.dragging]="!allowOpening"
    [class.opening]="allowOpening"
  >
    <strong>{{ title }}</strong>
    <ng-container *ngIf="allowOpening; else draggingDiv">
      <span
        class="float-right arrow"
        [class.arrow-down]="isCollapsed"
        [class.arrow-up]="!isCollapsed"
      ></span>
    </ng-container>

    <ng-template #draggingDiv>
      <span class="float-right drag_handle"
        ><mat-icon>drag_handle</mat-icon></span
      >
    </ng-template>

    <div
      *ngIf="shouldShowPreview"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [class.with-image]="hasImage"
    >
      <app-s3-image-file
        [fileName]="imageUrl"
        class="preview-img"
        *ngIf="hasImage; else emptyFiller"
      ></app-s3-image-file>
      <ng-template #emptyFiller>
        <span></span>
      </ng-template>

      <span class="" [innerHtml]="text | safeHtml"></span>
    </div>
  </div>
  <div
    [@smoothCollapse]="collapseStatus"
    [class.body]="!isCollapsed"
    *ngIf="allowOpening"
  >
    <div fxLayout="row" fxLayoutAlign="end" *ngIf="showRemove">
      <app-button (onClick)="onRemove()" cssClass="red">Remove</app-button>
    </div>

    <div class="row default-language-div">
      <div class="col-sm-6">
        <label>Default Language</label>
      </div>
      <div class="col-sm-6">
        <mat-button-toggle-group
          [(ngModel)]="defaultLanguage"
          class="toggle-group"
        >
          <mat-button-toggle
            [value]="language"
            (change)="onDefaultLanguageChange($event.value)"
            *ngFor="let language of languages"
          >
            {{ language }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <form>
      <nav ngbNav #nav="ngbNav" [(activeId)]="activeNavId" class="nav-tabs">
        <ng-container [ngbNavItem] = "0">
          <a ngbNavLink >{{ defaultLanguage }}</a>
          <ng-template ngbNavContent>
            <ng-template
              *ngTemplateOutlet="
                mediaFormTemplate;
                context: { mediaForm: form ,isDefaultLanguage: true,language:defaultLanguage }
              "
            ></ng-template>
          </ng-template>
        </ng-container>

        <ng-container
          [ngbNavItem] = "i + 1"
          *ngFor="let mediaLanguage of getMediaLanguagesArray().controls; let i = index"
        >
          <a ngbNavLink  >{{ mediaLanguage.controls['language'].value }}</a>
          <ng-template ngbNavContent>
            <ng-template
              *ngTemplateOutlet="
                mediaFormTemplate;
                context: { mediaForm: mediaLanguage , isDefaultLanguage: false,language:language , index:i}
              "
            ></ng-template>
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </form>
  </div>
</div>

<ng-template #mediaFormTemplate
            let-mediaForm="mediaForm"
            let-index ="index"
            let-isDefaultLanguage="isDefaultLanguage"
            let-language="language"
            >
<div>
  <div class="row is-enabled">
    <label class="col-sm-4 col-form-label">Is Enabled</label>
    <div class="col-sm-6">
      <input
        class="form-check-input"
        type="checkbox"
        [ngModel]="mediaForm.enabled"
        (change)="onChangeEnableButton(language, mediaForm)"
        [disabled] = "isDefaultLanguage"
      />
    </div>
    <div  *ngIf="!isDefaultLanguage">
      <button mat-icon-button color="primary" (click)="onClickCopy(mediaForm)">
        <mat-icon>file_copy</mat-icon>
      </button>
    </div>
  </div>


  <div [formGroup]="mediaForm" [class.disabled]= "mediaForm.disabled" >
    <div class="container" *ngIf="showTextBlock">
      <ng-container *ngIf="config.text">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Is Styled</label>
          <div class="col-sm-10">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="is_styled"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Text</label>
          <div class="col-sm-10">
            <ng-container *ngIf="isStyled(mediaForm); else notStyled">
              <tinymce formControlName="text"></tinymce>
            </ng-container>
            <ng-template #notStyled>
              <textarea
                formControlName="text"
                class="form-control"
                dir="{{ mediaForm.get('text_direction').value }}"
              ></textarea>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <app-audio-input
        *ngIf="config.audio_url"
        label="Audio"
        formControlName="audio_url"
      ></app-audio-input>

      <app-video-input
      *ngIf="config.video_url"
      label="Video"
      formControlName="video_url"
    ></app-video-input>

      <div class="form-group row" *ngIf="config.text_color">
        <label class="col-sm-2 col-form-label">Text Color</label>
        <div class="col-sm-10">
          <app-color-picker formControlName="text_color"></app-color-picker>
        </div>
      </div>

      <!-- <div class="form-group row" *ngIf="config.language">
        <div class="col-sm-2">
          <label>Language</label>
        </div>
        <div class="col-sm-10">
          <mat-button-toggle-group
            formControlName="language"
            class="toggle-group"
          >
            <mat-button-toggle
              [value]="language"
              (change)="onLanguageChange($event.value)"
              *ngFor="let language of languages"
            >
              {{ language }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div> -->

      <div class="form-group row" *ngIf="config.text_direction">
        <div class="col-sm-2">
          <label>Text Direction</label>
        </div>
        <div class="col-sm-10">
          <mat-button-toggle-group
            formControlName="text_direction"
            class="toggle-group"
          >
            <mat-button-toggle
              [value]="textDirection"
              *ngFor="let textDirection of textDirections"
            >
              {{ textDirection }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.layout_alignment">
        <div class="col-sm-2">
          <label>Layout Alignment</label>
        </div>
        <div class="col-sm-10">
          <mat-button-toggle-group
            formControlName="layout_alignment"
            class="toggle-group"
          >
            <mat-button-toggle
              [value]="layout_alignment"
              *ngFor="let layout_alignment of layoutAlignments"
            >
              {{ layout_alignment }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.widget_type">
        <label class="col-sm-2 col-form-label">Style</label>
        <div class="col-sm-10">
          <ng-select
            [items]="styles"
            bindLabel="title"
            bindValue="value"
            formControlName="widget_type"
            placeholder="Bubble or none. None by default"
          ></ng-select>
        </div>
      </div>

      <app-image-input
        *ngIf="config.avatar_image_url"
        label="Avatar Image"
        formControlName="avatar_image_url"
      ></app-image-input>
    </div>

    <div class="container" *ngIf="config.image_color || config.image_url">
      <app-image-input
        *ngIf="config.image_url"
        label="Main Image"
        formControlName="image_url"
      ></app-image-input>

      <div class="form-group row" *ngIf="config.image_color">
        <label class="col-sm-2 col-form-label">Color</label>
        <div class="col-sm-10">
          <app-color-picker formControlName="image_color"></app-color-picker>
        </div>
      </div>
    </div>

    <div *ngIf="config.is_correct || config.background_color">
      <label><strong>Other Properties</strong></label>

      <div class="form-group row" *ngIf="config.is_correct && isDefaultLanguage">
        <div class="col-sm-2">
          <label>Is correct</label>
        </div>
        <div class="col-sm-10">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="is_correct"
            />
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="config.background_color && isDefaultLanguage">
        <label class="col-sm-2 col-form-label">Background Color</label>
        <div class="col-sm-10">
          <app-color-picker
            formControlName="background_color"
          ></app-color-picker>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="config.with_tts || config.has_speech_marks">
      <ng-container *ngIf="config.with_tts">
        <div class="form-group row">
          <div class="col-sm-2">
            <label>Is TTS</label>
          </div>
          <div class="col-sm-10">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="is_tts"
              />
            </div>
          </div>
        </div>

        <div
          class="form-group row"
          *ngIf="
            config.has_speech_marks &&
            config.with_tts &&
            isTtsSelected(mediaForm) &&
            !isStyled(mediaForm)
          "
        >
          <div class="col-sm-2">
            <label>Has Speech Marks</label>
          </div>
          <div class="col-sm-10">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="has_speech_marks"
              />
            </div>
          </div>
        </div>

        <ng-container *ngIf="isTtsSelected(mediaForm)">
          <app-tts-form
          formControlName="tts"
          textDirection="{{ mediaForm.get('text_direction').value }}"
          layoutAlignment="{{ mediaForm.get('layout_alignment').value }}"></app-tts-form>
        </ng-container>
      </ng-container>
    </div>

    <div class="container" *ngIf="config.properties && isDefaultLanguage">
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Properties</label>
        </div>
        <div class="col-sm-10">
          <app-media-properties-form
            formControlName="properties"
            [config]="config.properties"
          ></app-media-properties-form>
        </div>
      </div>
    </div>
  </div>
  </div>
</ng-template>
