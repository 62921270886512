<form [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row wrap" fxLayoutAlign="end">
    <app-stroked-button (onClick)="enableDragging()" *ngIf="!isDragging">Reorder answers</app-stroked-button>
    <app-stroked-button (onClick)="disableDragging()" *ngIf="isDragging">Edit answers</app-stroked-button>
  </div>

  <div [sortablejs]="answers" [sortablejsOptions]="sortablejsOptions" formArrayName="answers" fxLayout="column wrap" fxLayoutAlign="start" fxLayoutGap="5px">
    <app-media-form
      title="Answer #{{i}}"
      *ngFor="let answer of answersForm.controls; let i = index;"
      fxFlex="100"
      [formControlName]="i"
      [config]="config"
      [showRemove]="true"
      (remove)="removeAnswer(i)"
      [showPreview]="true"
      [allowOpening]="!isDragging"
      [defaultLayoutAlignment]="'center'"
    ></app-media-form>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="end">
    <app-stroked-button (onClick)="addNewAnswer()">Add New Answer</app-stroked-button>
  </div>
</form>
