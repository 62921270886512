<form [formGroup]="form">
  <ng-select
    [items]="allStatus"
    [clearable]="false"
    [ngModel]="defaultStatus"
    bindLabel="title"
    bindValue="value"
    formControlName="status"
    placeholder=""
  ></ng-select>
</form>
