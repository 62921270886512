import {Component, Inject, OnInit} from '@angular/core';
import {ConceptModel} from "../../models/concept.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ChapterModel} from "../../models/chapter.model";
import {ItemControllerService} from "../../../../shared/services/item-controller.service";
import {chapterMediaConfig} from "../../utils/media-configs";
import {InjectNames} from "../../inject-names";

@Component({
  selector: 'app-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['./concept.component.scss']
})
export class ConceptComponent implements OnInit {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    @Inject(InjectNames.CONCEPT_ITEM) private itemController: ItemControllerService<ConceptModel, ChapterModel>,
  ) {
    this.itemController.fetch(this.route.snapshot.params['id']);
  }

  get concept(): ConceptModel {
    return this.itemController.item;
  }

  get chapters(): ChapterModel[] {
    return this.itemController.items;
  }

  get selectedChapter(): ChapterModel {
    return this.itemController.selected;
  }

  get mediaConfig() {
    return chapterMediaConfig;
  }

  imageUrlExtractor = (item: ChapterModel) => {
    return item.media?.image_url;
  }

  titleExtractor = (item: ChapterModel) => {
    return item.media?.text;
  }

  selectedExtractor = (item: ChapterModel) => {
    return item.id === this.selectedChapter?.id || false;
  }

  statusExtractor = (item: ChapterModel) => {
    return item?.status ?? 'active';
  }

  ngOnInit(): void {
    this.itemController.unselect();
  }

  createNewChapter($event: MouseEvent) {
    this.itemController.unselect();
  }

  regenerateTTS() {
    this.itemController.regenerateTTS();
  }

  saveOrder(items: any[]) {
    this.itemController.reorder(items);
  }

  selectChapter(obj) {
    var $event = obj.$event;
    if ($event.metaKey || $event.ctrlKey || $event.keyCode == 91 || $event.keyCode == 224)
    {
      const url = this.router.createUrlTree(['chapters/', obj.item.id])
      window.open(url.toString(), '_blank');
    }else{
    this.router.navigate(['chapters/', obj.item.id]);
    }
  }

  editChapter(chapter: ChapterModel) {
    this.itemController.select(chapter);
  }

  onSave(value: any) {
    this.itemController.save({...value, concept_id: this.concept?.id});
  }
}
