import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadFormComponent} from './components/upload-form/upload-form.component';
import {MediaLibFilesComponent} from './components/files/media-lib-files.component';
import {DialogComponent} from './components/dialog/dialog.component';
import {ImageInputComponent} from './components/image-input/image-input.component';
import {SharedModule} from '../../shared/shared.module';
import {AudioInputComponent} from './components/audio-input/audio-input.component';
import {AudioFilesComponent} from './components/audio-files/audio-files.component';
import {ImageFilesComponent} from './components/image-files/image-files.component';
import {VideoFilesComponent} from './components/video-files/video-files.component';
import { VideoInputComponent } from './components/video-input/video-input.component';


@NgModule({
  declarations: [
    UploadFormComponent,
    MediaLibFilesComponent,
    DialogComponent,
    ImageInputComponent,
    AudioInputComponent,
    AudioFilesComponent,
    ImageFilesComponent,
    VideoFilesComponent,
    VideoInputComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    DialogComponent,
    ImageInputComponent,
    AudioInputComponent,
    VideoFilesComponent,
    VideoInputComponent,
  ]
})
export class MediaLibModule {
}
