import {Component, OnInit, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true,
  }]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {

  onChange: (_: any) => {};
  onTouch: (_: any) => {};
  color: string;

  constructor() {
  }

  ngOnInit() {
  }


  writeValue(value: string) {
    this.color = value;
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }


  registerOnTouched(fn: (_: any) => {}) {
    this.onTouch = fn;
  }

  textColorChanged(color: string) {
    this.color = color;
    this.onChange(color);
    this.onTouch(color);
  }
}
