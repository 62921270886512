import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ModulesRoutingModule} from './modules-routing.module';
import {ContentBytesModule} from './content-bytes/content-bytes.module';
import {ContentBitsModule} from './content-bits/content-bits.module';
import {MediaLibModule} from './media-lib/media-lib.module';
import {SharedModule} from '../shared/shared.module';
import {AuthenticationModule} from './authentication/authentication.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ModulesRoutingModule,
    ContentBytesModule,
    SharedModule,
    MediaLibModule,
    ContentBitsModule,
    AuthenticationModule,
  ]
})
export class ModulesModule {
}
