import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { defaultMediaConfig, MediaConfig } from '../../config/media.config';
import { defaultTtsVoiceName } from '../tts-voice-name-select/tts-voice-name-select.component';
import { AuthService } from '../../../authentication/services/auth.service';
import { Subscription } from 'rxjs';
import { inputs } from '@syncfusion/ej2-angular-layouts/src/splitter/splitter.component';

export const defaultTextColor = '#989898';

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss' ,'/src/sass/shared.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaFormComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('smoothCollapse', [
      state(
        'initial',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'final',
        style({
          overflow: 'hidden',
          opacity: '1',
        })
      ),
      transition('initial=>final', animate('250ms')),
      transition('final=>initial', animate('250ms')),
    ]),
  ],
})
export class MediaFormComponent
  implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, OnDestroy
{
  form: FormGroup;
  @Input()
  config: MediaConfig = defaultMediaConfig;
  @Input()
  title = 'Media Object';
  @Input()
  isCollapsed = true;
  @Input()
  allowOpening = true;
  @Input()
  defaultLayoutAlignment = 'right';
  @Output()
  remove = new EventEmitter();
  public styles = [
    {
      title: 'None',
      value: null,
    },
    {
      title: 'Bubble',
      value: 'bubble',
    },
    {
      title: 'Bubble-cartoon',
      value: 'bubble_cartoon',
    },
  ];
  @Input()
  showPreview = false;
  @Input()
  showRemove = false;
  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private cd :ChangeDetectorRef,
    ) {}

  defaultLanguage = "AR";
  activeNavId = 0;

  private getDefaultTextDirection(language: string): string {
    if (language == 'AR') return 'RTL';
    else return 'LTR';
  }

  private getDefaultLayoutAlignment(language: string): string {
    if(this.defaultLayoutAlignment == 'center')
      return 'center';

    if (language == 'AR')
      return 'right';

    return 'left';
  }

  get collapseStatus(): string {
    if (!this.allowOpening) {
      return 'initial';
    }

    return this.isCollapsed ? 'initial' : 'final';
  }

  public get imageUrlControl(): FormControl {
    return this.form.controls.image_url as FormControl;
  }

  isStyledControl(form): FormControl {
    return form?.controls.is_styled as FormControl;
  }

  public  hasSpeechMarksControl(form): FormControl {
    return form.controls.has_speech_marks as FormControl;
  }

  isStyled(form): boolean {
    return this.isStyledControl(form)?.value;
  }

  public get imageUrl(): string {
    return this.imageUrlControl?.value;
  }

  public get textControl(): FormControl {
    return this.form.controls.text as FormControl;
  }

  public get text(): string {
    return this.textControl?.value;
  }

  get hasImage(): boolean {
    return !!this.imageUrl;
  }

  isTtsControl(form): FormControl {
    return form?.controls.is_tts as FormControl;
  }

  isTtsSelected(form): boolean {
    return this.isTtsControl(form)?.value;
  }

  get isCorrectControl(): FormControl {
    return this.form.controls.is_correct as FormControl;
  }

  get isCorrectSelected(): boolean {
    return this.isCorrectControl.value;
  }

  get shouldShowPreview(): boolean {
    if (!this.showPreview) {
      return false;
    }

    if (!this.allowOpening) {
      return true;
    }

    return this.isCollapsed;
  }

  get languages(): string[] {
    return this.authService.languages;
  }

  get otherLanguages(): string[] {
    return this.authService.languages.filter((lang) => lang != this.defaultLanguage);
  }

  get textDirections(): string[] {
    return this.authService.textDirections;
  }

  get layoutAlignments(): string[] {
    return this.authService.layoutAlignments;
  }

  get showTextBlock(): boolean {
    return (
      this.config.text ||
      this.config.text_color ||
      this.config.audio_url ||
      this.config.layout_alignment ||
      this.config.text_direction ||
      this.config.language ||
      this.config.avatar_image_url ||
      this.config.widget_type
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach( (sub) => sub.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.config?.currentValue) {
      this.setDefaultConfig();
    }
  }

  onChange: any = () => {};

  onTouch: any = () => {};


  ngOnInit(): void {
    this.rebuildForm();
    this.setDefaultConfig();
  }

  ngAfterViewInit(): void {
    this.registerSubsucribers(this.form);
    (this.form.controls.media_languages as FormArray).controls.forEach(languageForm => {
      this.registerSubsucribers(languageForm as FormGroup);
    });

  }

  registerSubsucribers(form :FormGroup){
    this.isStyledControl(form).valueChanges.subscribe((x) => {
      if (x === true) {
        this.hasSpeechMarksControl(form).patchValue(false);
      }
    });

    this.subscriptions.push(form.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    }));

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onClickCopy(mediaForm :FormGroup){
     var object = this.form.value;
    delete object.id;
    delete object.language;
    delete object.layout_alignment;
    delete object.text_direction;
    mediaForm.patchValue(object);
    mediaForm.enable();
  }


  onDefaultLanguageChange(lang) {
    if(this.form.value.language == lang){
      return;
    }

    var oldParentObject = this.form.value;
    var newParentObject = oldParentObject.media_languages?.find((m) => m.language == lang );

    if(newParentObject === undefined){
      newParentObject = {'language':lang , 'media_languages':[]};
    }
    this.swapLanguages(oldParentObject, newParentObject);
    this.checkMediasWithoutLanguage(newParentObject);
    this.reorderLanguages(newParentObject);
    this.enableTabsThatHaveValues(newParentObject);
    this.resetFormWithDefaultValues();
    this.form.patchValue(newParentObject);
    this.activeNavId = 0;
  }

  swapLanguages(oldParent,newParent){
    var parentId = oldParent.id;
    var childId = newParent.id;
    oldParent.id = childId;
    newParent.id = parentId;
    var languagesArray = oldParent.media_languages?.filter((m)=> m.language != newParent.language) ?? [];
    languagesArray.push(oldParent);
    oldParent.media_languages = null;
    newParent.media_languages = languagesArray;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


  private enableTabsThatHaveValues(obj){
    obj.media_languages.forEach((ml, i) => {
      var mediaForm = this.getMediaLanguagesArray().controls[i];
      if(ml?.disabled){
        setTimeout(_ => mediaForm?.disable(), 1);
      }else{
       mediaForm?.enable();
      }
    });
  }

  private getMediaLanguagesArray(){
    return (this.form.get('media_languages') as FormArray);
  }

  getLanguageFrom(index) {
    return (this.form.get('media_languages') as FormArray).at(index);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  onChangeEnableButton(language,mediaForm:FormGroup){
    if(mediaForm.enabled){
      this.cd.detectChanges();
      mediaForm.disable();
      mediaForm.disable();
      mediaForm.markAsTouched();
    }
    else{
      mediaForm.enable();
    }
  }


  onRemove(): void {
    this.remove.emit();
  }

  collapseOrOpen(): void {
    if (this.allowOpening) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  getDefaultValueObject(language = this.defaultLanguage){
    return  {
      is_correct: false,
      is_tts: false,
      has_speech_marks: false,
      language: language,
      text_direction: this.getDefaultTextDirection(language),
      text_color: defaultTextColor,
      layout_alignment: this.getDefaultLayoutAlignment(language),
      media_languages: [],
    };
  }

  private resetFormWithDefaultValues(){
    var defaultValueObject = this.getDefaultValueObject();
    this.otherLanguages.forEach((language) =>
      defaultValueObject.media_languages.push(this.getDefaultValueObject(language))
    );

    this.form.reset(defaultValueObject);
  }


  private setDefaultConfig(): void {
    if (!this.config) {
      this.config = defaultMediaConfig;
    }
  }

  writeValue(obj: any): void {
    this.resetFormWithDefaultValues();

    if (obj) {
      if(!obj.language){
        obj.language = this.defaultLanguage;
      }
      this.defaultLanguage = obj.language;
      this.checkMediasWithoutLanguage(obj);
      this.reorderLanguages(obj);
      this.enableTabsThatHaveValues(obj);
      this.form.patchValue(obj);
    }
  }

  private checkMediasWithoutLanguage(obj){
    if(obj.media_languages === undefined){
      obj.media_languages = [];
    }
    var otherLanguages = this.otherLanguages;

    for(var i=0; i< this.otherLanguages.length; i++){
      try {
        if (obj.media_languages[i].language == null){
          obj.media_languages[i].language = otherLanguages.pop();
        }else{
          otherLanguages = otherLanguages.filter(l => l != obj.media_languages[i].language);
        }
      } catch (error) {
        obj.media_languages.push({language:otherLanguages.pop(), disabled: true});
      }
    }
  }

  private reorderLanguages(obj){

    var newMediaLanguages = [];
    for(var language of this.otherLanguages){
        var media = obj.media_languages.find(m => m.language == language);
        newMediaLanguages.push(media);

    }
    obj.media_languages = newMediaLanguages;
  }

  private buildMediaForm(language = this.defaultLanguage): FormGroup {
    return this.fb.group({
      id:[],
      text: [],
      is_styled: [],
      audio_url: [],
      video_url: [],
      text_color: [defaultTextColor],
      widget_type: [],
      avatar_image_url: [],
      image_url: [],
      image_color: [],
      is_correct: [],
      background_color: [],
      is_tts: [false],
      tts: [{ voice_name: defaultTtsVoiceName }],
      properties: [],
      language: [language],
      text_direction: [this.getDefaultTextDirection(language)],
      layout_alignment: [this.getDefaultLayoutAlignment(language)],
      has_speech_marks: [false],
    });
  }

  private builLanguagesForm(): FormArray {
    var languagesForm = this.fb.array([]);
    for (var language of this.otherLanguages) {
      var mediaForm = this.buildMediaForm(language);
      mediaForm.disable();
      languagesForm.push(mediaForm);
    }
    return languagesForm;
  }

  private rebuildForm(): void {
    this.form = this.buildMediaForm();
    this.form.addControl('media_languages', this.builLanguagesForm());
  }
}
