import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ConceptModel} from "../../models/concept.model";
import {SubjectModel} from "../../models/subject.model";
import {ItemControllerService} from "../../../../shared/services/item-controller.service";
import {conceptMediaConfig} from "../../utils/media-configs";
import {InjectNames} from "../../inject-names";

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss']
})
export class SubjectComponent implements OnInit {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    @Inject(InjectNames.SUBJECT_ITEM) private itemController: ItemControllerService<SubjectModel, ConceptModel>,
  ) {
    this.itemController.fetch(this.route.snapshot.params['id']);
  }

  get subject(): SubjectModel {
    return this.itemController.item;
  }

  get concepts(): ConceptModel[] {
    return this.itemController.items;
  }

  get selectedConcept(): ConceptModel {
    return this.itemController.selected;
  }

  get mediaConfig() {
    return conceptMediaConfig;
  }

  ngOnInit(): void {
    this.itemController.unselect();
  }

  imageUrlExtractor = (item: ConceptModel) => {
    return item.media?.image_url;
  }

  titleExtractor = (item: ConceptModel) => {
    return item.media?.text;
  }

  selectedExtractor = (item: ConceptModel) => {
    return item.id === this.selectedConcept?.id || false;
  }

  statusExtractor = (item: ConceptModel) => {
    return item?.status ?? 'active';
  }

  createNewConcept($event: MouseEvent) {
    this.itemController.unselect();
  }

  saveOrder(items: any[]) {
    this.itemController.reorder(items);
  }

  selectConcept(obj) {
    var $event = obj.$event;
    if ($event.metaKey || $event.ctrlKey || $event.keyCode == 91 || $event.keyCode == 224)
    {
      const url = this.router.createUrlTree(['concepts/', obj.item.id])
      window.open(url.toString(), '_blank');
    }else{
    this.router.navigate(['concepts/', obj.item.id]);
    }
  }


  editConcept(concept: ConceptModel) {
    this.itemController.select(concept);
  }

  onSave(value: any) {
    this.itemController.save({...value, subject_id: this.subject?.id});
  }

  regenerateTTS() {
    this.itemController.regenerateTTS();
  }
}
