import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {
  }

  public openSnackBar(data: any, panelClass: string, snackType?: string, duration: number = 3000): void {
    if (snackType !== undefined) {
      snackType = 'success';
    }

    this.snackBar.openFromComponent(SnackBarComponent, {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: `sb-${panelClass}`,
      data: {data, snackType}
    });
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }
}
