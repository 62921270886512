import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TokenModel} from '../models/token.model';
import {LoginModel} from '../models/login.model';
import {InitCallService} from '../../../core/services/init-call.service';
import {ActionNames} from '../../content-bytes/action-names';
import {LocalStorageService} from '../../../shared/services/local-storage.service';
import {BucketConfigModel} from '../../../core/models/bucket-config.model';
import {ActionsModel} from '../../../core/models/actions.model';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static TOKEN_KEY = 'TOKEN_KEY';
  tokenModel: TokenModel = null;
  isLoading = new BehaviorSubject(false);
  isLoggedIn = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private initCall: InitCallService,
    private localStorageService: LocalStorageService,
  ) {
    this.retrieveTokenFromLocalStorage();
  }

  get bucketConfig(): BucketConfigModel {
    return this.tokenModel?.bucket_config;
  }

  get languages(): string[] {
    return this.tokenModel?.languages;
  }

  get textDirections(): string[] {
    return this.tokenModel?.text_directions;
  }

  get layoutAlignments(): string[] {
    return this.tokenModel?.layout_alignments;
  }

  get actions(): ActionsModel {
    return this.tokenModel?.actions;
  }

  private get loggedInStatus(): boolean {
    return !!this.tokenModel;
  }

  public urlByAction(actionName: string): string {
    if (actionName in this.actions) {
      return environment.api_url + this.actions[actionName].href;
    }

    return null;
  }

  logout(): void {
    this.isLoading.next(true);
    this.localStorageService.clear(AuthService.TOKEN_KEY);
    this.isLoading.next(false);

    this.isLoggedIn.next(false);
  }

  login(value: LoginModel): Observable<TokenModel> {
    this.isLoading.next(true);

    return this.http.post<TokenModel>(this.initCall.urlByAction(ActionNames.LOGIN), value)
      .pipe(
        tap((item) => {
          this.isLoading.next(false);
        }),
        tap((item) => {
          this.tokenModel = item;

          this.localStorageService.store(AuthService.TOKEN_KEY, this.tokenModel);
          this.isLoggedIn.next(this.loggedInStatus);
        }),
      );
  }

  private retrieveTokenFromLocalStorage(): void {
    this.tokenModel = this.localStorageService.getItem(AuthService.TOKEN_KEY);
    this.isLoggedIn.next(this.loggedInStatus);
  }
}
