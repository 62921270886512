import { AfterViewInit, Component, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';



export enum switchType {
  empty = "empty",
  pullOut = "pull-out",
  pullHorizontally = "pull-horizontally"
}

export const defaultSwitchType = switchType.pullOut;

@Component({
  selector: 'app-switch-type-select',
  templateUrl: './switch-type-select.component.html',
  styleUrls: ['./switch-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchTypeSelectComponent),
      multi: true,
    }
  ],
})

export class SwitchTypeSelectComponent implements OnInit , AfterViewInit, OnChanges, ControlValueAccessor {

  switchType: FormControl;
  allSwitchTypes: { title: string, value: string }[] = [
    {
      title: switchType.empty.valueOf(),
      value: switchType.empty.valueOf(),
    },
    {
      title: switchType.pullOut.valueOf(),
      value: switchType.pullOut.valueOf(),
    },
    {
      title: switchType.pullHorizontally.valueOf(),
      value: switchType.pullHorizontally.valueOf(),
    },
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.switchType = this.fb.control(this.defaultSwitchType);
  }

  get defaultSwitchType(): string {
    return defaultSwitchType;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.switchType) {
      this.switchType.patchValue(changes.switchType);
    }

    this.switchType.markAsPristine();
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  ngAfterViewInit(): void {
    this.switchType.valueChanges.subscribe((x) => {
      this.onChange(x);
      this.onTouch(x);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: any): void {
    if (val) {
      this.switchType.patchValue(val);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.switchType.disable();
    } else {
      this.switchType.enable();
    }
  }
}
