import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../components/dialog/dialog.component';
import {ImageFile} from '../models/image-file.model';
import {Observable} from 'rxjs';
import {Input} from '../models/input.model';
import {BucketFoldersInjectNames} from '../../content-bytes/inject-names';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  constructor(public dialog: MatDialog) {
  }

  open(selected?: ImageFile): Observable<Input> {
    return this.dialog.open<DialogComponent, Input, Input>(
      DialogComponent,
      {
        data: {
          selected_image: selected,
          injectName: BucketFoldersInjectNames.VIDEOS,
        },
      }
    ).afterClosed();
  }
}
