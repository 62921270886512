<app-media-lib-files
  (onSelect)="select($event)"
  (onRefresh)="refresh()"
  (onSearch)="search($event)"
  [isLoading]="isLoading"
  [query]="query"
  title="Audio Files"
>

  <div files>
    <mat-card class="image-card" *ngFor="let file of files; trackBy:trackFunc" (click)="selectCard(file)"
              [ngClass]="{'selected': selected?.name == file.name}">
      <p [matTooltip]="file.name" class="header">{{file.name}}</p>
      <div class="div-image">
        <app-s3-audio-file [fileName]="file.name"></app-s3-audio-file>
      </div>
    </mat-card>
  </div>


</app-media-lib-files>
