import  {MediaConfig} from './media.config'

export interface AnswerConfig extends MediaConfig {
  required?: boolean;
}

export const defaultAnswerConfig: AnswerConfig = {
  required: true,
  text: true,
  image_url: true,
  audio_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  properties: null,
  dynamic_links: false,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
};
