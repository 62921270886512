import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { inputs } from '@syncfusion/ej2-angular-layouts/src/splitter/splitter.component';

@Component({
  selector: 'app-sortable-cards-viewer',
  templateUrl: './sortable-cards-viewer.component.html',
  styleUrls: ['./sortable-cards-viewer.component.scss']
})
export class SortableCardsViewerComponent implements OnInit {
  @Input()
  items: any[];

  @Input()
  showImport = false;

  @Input()
  imageUrlLabel: (_: any) => string;

  @Input()
  titleLabel: (_: any) => string;

  @Input()
  tagsLabel: (_: any) => string;

  @Input()
  isSelected: (_: any) => boolean;

  @Input()
  status: (_: any) => string;

  @Input()
  showCopyButton?: boolean = false;

  @Output()
  save = new EventEmitter<any>();

  @Output()
  copyItem = new EventEmitter<any>();

  @Output()
  create = new EventEmitter();

  @Output()
  showContent = new EventEmitter<any>();

  @Output()
  edit = new EventEmitter<any>();

  showForm = false;

  @Output()
  import = new EventEmitter<FileList>();

  constructor() {
  }

  ngOnInit(): void {
  }

  extractImageUrl(item: any): string {
    if (this.imageUrlLabel) {
      return this.imageUrlLabel(item);
    }

    return null;
  }

  extractTitle(item: any): string {
    if (this.titleLabel) {
      return this.titleLabel(item);
    }

    return null;
  }

  onCreate(): void {
    this.create.emit();
    this.showForm = true;
  }

  onSave(items: any): void {
    this.save.emit(items);
  }

  extractTags(item: any): string {
    if (this.tagsLabel) {
      return this.tagsLabel(item);
    }

    return null;
  }

  extractStatus(item : any){
    if(this.status){
      return this.status(item);
    }
    return null;
  }

  isActive(item: any){
    return this.extractStatus(item) === "active"
  }

  extractSelected(item: any): boolean {
    if (this.isSelected) {
      return this.isSelected(item);
    }

    return false;
  }

  onEdit(item: any): void {
    this.edit.emit(item);
    this.showForm = true;
  }

  onShowContent(item: any, $event): void {
    this.showContent.emit({item:item,$event: $event});
  }

  onImport(files: FileList): void {
    this.import.emit(files);
  }

  onCopy(item: any) {
    this.copyItem.emit(item);
  }
}
