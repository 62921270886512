export enum ActionNames {
  SUBJECTS = 'subjects',
  CONCEPTS = 'concepts',
  CHAPTERS = 'chapters',
  CONTENT_BYTES = 'content_bytes',
  CONTENT_BITS = 'content_bits',
  LOGIN = 'login',
  AGE_GROUPS = 'age_groups',
  SAVE_CHAPTER_WORKBOOK = 'save_chapter_workbook',
  GET_TASHKEEL = 'get_tashkeel',
  SHOW_SUBJECT_DYNAMIC_LINK = 'show_subject_dynamic_link',
  PLAY_CONTENT_BYTE_DYNAMIC_LINK = 'play_content_byte_dynamic_link',
  TRACING_SHAPES= 'tracing_shapes',
  RESET_CREATED_DATE_OF_CONTENT_BYTE = 'reset_created_date_of_content_byte',
  REGENERATE_DIRTY_TTS = 'regenerate_dirty_tts'
}
