import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {InjectNames} from "../../inject-names";
import {ListControllerService} from "../../../../shared/services/list-controller.service";
import {AgeGroupModel} from "../../models/age-group.model";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-age-groups-form',
  templateUrl: './age-groups-form.component.html',
  styleUrls: ['./age-groups-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgeGroupsFormComponent),
      multi: true,
    }
  ]
})
export class AgeGroupsFormComponent implements OnInit, ControlValueAccessor {

  selectedAgeGroupIds: number[] = [];
  disabled: boolean = false;
  onChange: (_: any) => {};
  onTouch: (_: any) => {};

  constructor(@Inject(InjectNames.AGE_GROUPS) private listController: ListControllerService<AgeGroupModel>) {
    this.listController.fetchAll();
  }

  get ageGroups() {
    return this.listController.items;
  }

  getButtonStatus(ageGroupId: number): boolean {
    return this.selectedAgeGroupIds.includes(ageGroupId);
  }

  ngOnInit(): void {
  }

  onButtonValueChanges(event) {
    const buttonId = parseInt(event.source.id);
    const isButtonChecked = event.source._checked;
    if (isButtonChecked) {
      this.selectedAgeGroupIds.push(buttonId);
    } else {
      const elementIdx = this.selectedAgeGroupIds.indexOf(buttonId);
      if (elementIdx > -1) {
        this.selectedAgeGroupIds.splice(elementIdx, 1);
      }
    }

    this.onValueChanges();
  }

  onSelectAll(): void {
    this.selectedAgeGroupIds = this.listController.items.map((ageGroup) => ageGroup.id);
    this.onValueChanges();
  }

  onClearSelection(): void {
    this.selectedAgeGroupIds = [];
    this.onValueChanges();
  }

  onValueChanges(): void {
    this.onChange(this.selectedAgeGroupIds);
    this.onTouch(this.selectedAgeGroupIds);
  }

  writeValue(age_groups_ids: any[]): void {
    if (age_groups_ids?.length > 0) {
      this.selectedAgeGroupIds = age_groups_ids;
    } else {
      this.selectedAgeGroupIds = [];
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: any) => {}) {
    this.onTouch = fn;
  }

}
