import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { inputs } from '@syncfusion/ej2-angular-layouts/src/splitter/splitter.component';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss' , '/src/sass/shared.scss']
})
export class TileComponent implements OnInit {

  @Input()
  id?: number;

  @Input()
  tags?: string[];

  @Input()
  title?: string;

  @Input()
  imageUrl?: string;

  @Input()
  selected?: boolean;

  @Input()
  isActive?: boolean;

  @Input()
  textDirection?: string;

  @Output()
  edit = new EventEmitter();

  @Output()
  copyItem = new EventEmitter();

  @Output()
  showContent = new EventEmitter();


  constructor() {
  }

  ngOnInit(): void {
  }

  onEdit($event: MouseEvent) {
    this.edit.emit();
  }

  onCopy($event: MouseEvent) {
    this.copyItem.emit();
  }

  onShowContent($event: MouseEvent) {
    this.showContent.emit();
  }
}
