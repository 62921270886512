import { Injectable } from '@angular/core';
import { Api } from '../interfaces/api';
import { SubjectModel } from '../../modules/content-bytes/models/subject.model';
import { BearerHttpClient } from '../../modules/authentication/services/bearer-http-client.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../modules/authentication/services/auth.service';
import { DynamicLinkResponseModel } from '../../modules/content-bytes/models/dynamic-link-response.model';

@Injectable()
export class ApiManagerService<T> implements Api {
  constructor(
    private http: BearerHttpClient,
    private authService: AuthService,
    private actionName: string,
    private dynamicLinkActionName: string,
    private resetCreatedDateActionName: string
  ) {}

  get url(): string {
    return this.authService.urlByAction(this.actionName);
  }

  fetchAll(): Observable<SubjectModel[]> {
    return this.http.get<SubjectModel[]>(this.url);
  }

  store(json: any): Observable<SubjectModel> {
    return this.http.post<SubjectModel>(this.url, json);
  }

  update(id: number, json: any): Observable<SubjectModel> {
    return this.http.put<SubjectModel>(this.url + '/' + id, json);
  }

  reorder(item_id, items: any[]): Observable<any> {
    return this.http.put(this.url + '/reorder', {
      item_id: item_id,
      ids: items.map((item) => item.id),
    });
  }


  fetch(id: number): Observable<T> {
    return this.http.get<T>(this.url + '/' + id);
  }

  import<K>(actionName: string, body: any): Observable<K> {
    return this.http.post<K>(this.authService.urlByAction(actionName), body);
  }

  generateDynamicLink(id: number): Observable<DynamicLinkResponseModel> {
    return this.http.post<DynamicLinkResponseModel>(
      this.authService.urlByAction(this.dynamicLinkActionName),
      { id }
    );
  }

  resetCreatedDate(id: number): any {
    return this.http.put(this.url + '/reset_create_date/' + id, {});
  }

  regenerateTTS(id: number): Observable<any> {
    return this.http.post(this.url + '/regenerate_tts/' + id, {});
  }

  copy(id: number): any {
    return this.http.post(this.url + '/' + id, {});
  }
}
