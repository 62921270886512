import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InitCallModel} from '../models/init-call.model';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ActionsModel} from '../models/actions.model';

@Injectable({
  providedIn: 'root'
})
export class InitCallService {

  initCallModel: InitCallModel;
  isLoaded = false;

  constructor(
    private http: HttpClient,
  ) {
  }

  get actions(): ActionsModel {
    return this.initCallModel?.actions;
  }

  public urlByAction(actionName: string): string {
    if (actionName in this.actions) {
      return environment.api_url + this.actions[actionName].href;
    }
    return null;
  }

  public fetch(): Observable<InitCallModel> {
    return this.http.get<InitCallModel>(environment.api_url + environment.init_call)
      .pipe(
        tap((initModel) => {
          this.initCallModel = initModel;

          this.isLoaded = true;
        })
      );
  }
}
