import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {of} from 'rxjs';
import {InitCallService} from '../services/init-call.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InitCallGuard implements CanActivate, CanActivateChild {
  constructor(
    private initCallService: InitCallService,
  ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.fetchInitModel();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.fetchInitModel();
  }

  private fetchInitModel(): Promise<boolean> | boolean {
    if (this.initCallService.isLoaded) {
      return of(true).toPromise();
    }

    return this.initCallService.fetch().pipe<boolean>(
      map((initModel) => {
          return !!initModel;
        }
      )
    ).toPromise();
  }
}
