import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ContentBitPropertiesConfig } from '../../config/content-bit-properties.config';
import { defaultSwitchType } from '../switching/switch-type-select/switch-type-select.component';
import { defaultSwitchSlot } from '../switching/switch-slot-select/switch-slot-select.component';
import { videoContainerType , defaultVideoContatainerType }  from '../../enums/video-container-type.enum';
@Component({
  selector: 'app-properties-form',
  templateUrl: './properties-form.component.html',
  styleUrls: ['./properties-form.component.scss' , '/src/sass/shared.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PropertiesFormComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('smoothCollapse', [
      state(
        'initial',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'final',
        style({
          overflow: 'hidden',
          opacity: '1',
        })
      ),
      transition('initial=>final', animate('250ms')),
      transition('final=>initial', animate('250ms')),
    ]),
  ],
})
export class PropertiesFormComponent
  implements OnInit, AfterViewInit, ControlValueAccessor {

  form: FormGroup;

  @Input()
  isCollapsed = true;

  @Input()
  allowOpening = true;

  @Input()
  config: ContentBitPropertiesConfig = null;

  @Input()
  title = 'Properties';

  constructor(private fb: FormBuilder) {}

  arabicLanguage = {
    value: 'arabic',
    text: 'Arabic',
  };

  hinduLanguage = {
    value: 'hindu',
    text: 'Hindu',
  };

  languages = [this.arabicLanguage, this.hinduLanguage];
  private defaultLanguage = this.arabicLanguage.value;
  private defaultWordSimilarityThreshold = 10;
  private defaultSentenceSimilarityThreshold = 10;


  allVideoContainerType: { title: string, value: string }[] = [
    { title: 'full screen', value: videoContainerType.fullScreen.valueOf() },
    { title: 'inline', value: videoContainerType.inline.valueOf() }
  ]

  get collapseStatus(): string {
    if (!this.allowOpening) {
      return 'initial';
    }

    return this.isCollapsed ? 'initial' : 'final';
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouch(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.form.reset({});

    if (value) {
      this.form.patchValue({
        language: this.defaultLanguage,
        ...value,
      });
    } else {
      this.form.patchValue({
        language: this.defaultLanguage,
        switch_type: defaultSwitchType,
        switch_slot: defaultSwitchSlot,
        word_similarity_threshold : this.defaultWordSimilarityThreshold,
        sentence_similarity_threshold : this.defaultSentenceSimilarityThreshold,
        video_container: defaultVideoContatainerType,
        show_controls: false,
      });
    }
  }

  ngOnInit(): void {
    this.rebuildForm();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  collapseOrOpen(): void {
    if (this.allowOpening) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  private rebuildForm(): void {
    this.form = this.fb.group({
      language: [this.defaultLanguage],
      thousand: [],
      decimal: [],
      columns_count: [],
      rows_count: [],
      error_margin: [],
      hide_original_image: [],
      switch_type: [defaultSwitchType],
      switch_slot: [defaultSwitchSlot],
      word_similarity_threshold: [this.defaultWordSimilarityThreshold],
      sentence_similarity_threshold: [this.defaultSentenceSimilarityThreshold],
      video_container: [defaultVideoContatainerType],
      show_controls: [false],
    });
  }
}
